import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import

// types
import { GuardProps } from 'types/auth';
import { useSelector } from 'react-redux';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const loginReducer = useSelector((state: any) => state.loginReducer);
  const user = localStorage.getItem('userInfo');
  const isLoggedIn: any = user ? true : false || loginReducer.isLoggedIn;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('login', {
        state: {
          from: location.pathname + location.search
        },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;

/* eslint-disable @typescript-eslint/no-unused-expressions */
import { DeepPartial } from '@reduxjs/toolkit';
import endpoints from 'constants/endpoints';
import axiosServices from 'utils/axios';
import { errorMessageSnackbar, successMessageSnackbar } from 'utils/snackbar-message';

export const getMyProfile = async (): Promise<UserProfile> => {
  try {
    const response = (await axiosServices.get(endpoints.myProfile)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response.data;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
    throw err;
  }
};

export const getMyRoles = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getMyRoles)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response.data;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const updateMyProfile = async (data: DeepPartial<UserProfile>): Promise<any> => {
  try {
    const response = (await axiosServices.put(endpoints.myProfile, data)).data;
    response.error ? errorMessageSnackbar(response.message) : successMessageSnackbar('Profile  updated successfully.');
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const deleteMyProfile = async (): Promise<any> => {
  try {
    const response = (await axiosServices.delete(endpoints.myProfile)).data;
    response.error ? errorMessageSnackbar(response.message) : successMessageSnackbar('Profile  deleted successfully.');
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const changeMyPassword = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.put(endpoints.resetPassword, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const getProfileSettings = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.userNotification)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response.data;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const updateProfileSettings = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.patch(endpoints.userNotification, data)).data;
    response.error ? errorMessageSnackbar(response.message) : successMessageSnackbar('Settings updated successfully.');
    return response.data;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export interface UserProfile {
  roles: string[];
  id: number;
  uniqueId: string;
  username: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  company: {
    id: number;
    companyName: string;
    address: string;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
  };
}

import { Box, Typography } from '@mui/material';
import { DEFAULT_OCEAN_VOYAGE_DURATION_DAYS } from 'constants/constants';
import { differenceInCalendarDays } from 'date-fns';
import React from 'react';
import { FinishFlagIcon } from 'sections/booking-list-detail/BookingVisualizedStatus/FinishFlagIcon';
import { ShipIcon } from 'sections/booking-list-detail/BookingVisualizedStatus/ShipIcon';
import { StepDot } from 'sections/booking-list-detail/BookingVisualizedStatus/StepDot';
import { StraightConnector } from 'sections/booking-list-detail/BookingVisualizedStatus/StraightConnector';
import TerminalIcon from 'sections/booking-list-detail/BookingVisualizedStatus/TerminalIcon';
import TruckIcon from 'sections/booking-list-detail/BookingVisualizedStatus/TruckIcon';
import WarehouseIcon from 'sections/booking-list-detail/BookingVisualizedStatus/WarehouseIcon';
import { WavyConnector } from 'sections/booking-list-detail/BookingVisualizedStatus/WavyConnector';
import { WavySample } from 'sections/booking-list-detail/BookingVisualizedStatus/WavySample';
import {
  selectBookingLifecycleStatus,
  selectEtaDateString,
  selectHasOceanVoyage,
  selectLastUpdatedDate,
  selectRoadwayProgress
} from 'sections/booking-list-detail/selectors';

const BOOKING_STEPS = ['booked', 'ocean_voyage', 'terminal', 'roadway', 'completed'] as const;

export type BookingStep = typeof BOOKING_STEPS[number];

export interface BookingVisualizedStatusProps extends React.ComponentProps<typeof Box> {
  bookingDetail: any;
}

export const BookingVisualizedStatus: React.FC<BookingVisualizedStatusProps> = ({ bookingDetail, ...boxProps }) => {
  const step = selectBookingLifecycleStatus(bookingDetail);
  const hasOceanVoyage = selectHasOceanVoyage(bookingDetail);
  const etaDateString = selectEtaDateString(bookingDetail);
  const roadwayProgress = selectRoadwayProgress(bookingDetail);

  const oceanVoyageProgress = React.useMemo(() => {
    if (!etaDateString || Number.isNaN(Date.parse(etaDateString))) {
      return 50;
    }

    const etaDate = new Date(etaDateString);
    const daysLeft = differenceInCalendarDays(etaDate, new Date());

    if (daysLeft <= 0) {
      return 100;
    }

    return ((DEFAULT_OCEAN_VOYAGE_DURATION_DAYS - daysLeft) / DEFAULT_OCEAN_VOYAGE_DURATION_DAYS) * 100;
  }, [etaDateString]);

  const wavyConnectorProgress = React.useMemo(() => {
    if (step === 'terminal' || step === 'roadway' || step === 'completed') {
      return 100;
    }

    if (step === 'booked') {
      return 0;
    }

    return oceanVoyageProgress;
  }, [oceanVoyageProgress, step]);

  const straightConnectorProgress = React.useMemo(() => {
    if (step === 'completed') {
      return 100;
    }

    if (step === 'booked' || step === 'ocean_voyage' || step === 'terminal') {
      return 0;
    }

    return roadwayProgress ?? 100;
  }, [roadwayProgress, step]);

  const lastUpdatedDate = selectLastUpdatedDate(bookingDetail);

  return (
    <Box {...boxProps}>
      <Box display="flex" alignItems="center" m="36px 24px 24px 24px">
        <StepDot icon={step === 'booked' ? <TerminalIcon /> : undefined} />

        {hasOceanVoyage ? (
          <WavyConnector icon={step === 'ocean_voyage' ? <ShipIcon /> : undefined} progress={wavyConnectorProgress} />
        ) : (
          <StraightConnector progress={step === 'booked' ? 0 : 100} />
        )}

        <StepDot icon={step === 'terminal' ? <TerminalIcon /> : undefined} />

        <StraightConnector
          icon={step === 'roadway' && roadwayProgress !== undefined && roadwayProgress !== 100 ? <TruckIcon /> : undefined}
          progress={straightConnectorProgress}
        />

        <StepDot icon={step === 'roadway' && (roadwayProgress === undefined || roadwayProgress === 100) ? <WarehouseIcon /> : undefined} />

        <StraightConnector progress={step === 'completed' ? 100 : 0} />

        <StepDot icon={step === 'completed' ? <FinishFlagIcon /> : undefined} />
      </Box>

      <Box border="1px solid #F0F0F0"></Box>

      <Box m="14px 24px" display="flex" alignItems="center">
        <Typography fontSize={14} fontWeight={400} color="#8C8C8C" mr="10px">
          Last Update
        </Typography>
        <Typography fontSize={14} fontWeight={400} color="#262626" mr="auto">
          {lastUpdatedDate}
        </Typography>

        <WavySample />
        <Typography fontSize={14} fontWeight={400} color="#262626" ml="8px">
          Ocean
        </Typography>

        <Box width="40px" borderBottom="2px solid #1890FF" ml="20px" />
        <Typography fontSize={14} fontWeight={400} color="#262626" ml="8px">
          Road Way
        </Typography>
      </Box>
    </Box>
  );
};

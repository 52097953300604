import React from 'react';
import { Button } from '@mui/material';
import { EventUpcomingIcon } from 'components/icons/EventUpcomingIcon';
import { selectVesselLatitude } from 'sections/booking-list-detail/selectors';

export interface ShowVesselLocationButtonProps {
  bookingDetailData: any;
}

export const ShowVesselLocationButton: React.FC<ShowVesselLocationButtonProps> = ({ bookingDetailData }) => {
  const showVesselLocationUrl = React.useMemo(() => {
    return `https://maps.google.com/?q=${bookingDetailData?.tracking?.vesselLatitude},${bookingDetailData?.tracking?.vesselLongitude}`;
  }, [bookingDetailData?.tracking?.vesselLatitude, bookingDetailData?.tracking?.vesselLongitude]);

  return (
    <>
      {selectVesselLatitude(bookingDetailData) && selectVesselLatitude(bookingDetailData) ? (
        <a
          style={{ textDecoration: 'none', display: 'inline-block' }}
          href={showVesselLocationUrl}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          <Button variant="contained" color="primary" size="large" sx={{ display: 'flex', gap: '8px', width: '220px' }}>
            <EventUpcomingIcon height="15px" />
            {'Vessel Location'}
          </Button>
        </a>
      ) : null}
    </>
  );
};

import { format } from 'date-fns';

export type TransportationType = 'live_load' | 'drop_and_pick';

export type RoadwayStatus = 'picked_up' | 'dispatched' | 'picked_up_ongoing' | 'empty_picked_up' | 'completed';

export type LiveLoadRoadwayStatus = Omit<RoadwayStatus, 'empty_picked_up'>;
export type DropAndPickRoadwayStatus = RoadwayStatus;
export type StatusOptions<T extends string> = { value: T; title: string }[];

export const DROP_AND_PICK_ROADWAY_STATUS_OPTIONS: StatusOptions<RoadwayStatus> = [
  { value: 'picked_up', title: 'Picked Up' },
  { value: 'dispatched', title: 'In transit' },
  { value: 'picked_up_ongoing', title: 'Delivered' },
  { value: 'empty_picked_up', title: 'Empty Pick up' },
  { value: 'completed', title: 'Completed' }
];

export const LIVE_LOAD_ROADWAY_STATUS_OPTIONS = DROP_AND_PICK_ROADWAY_STATUS_OPTIONS.filter((option) => option.value !== 'empty_picked_up');

export function selectTransportationType(bookingDetail: any) {
  return bookingDetail?.transportationType as TransportationType;
}

export function selectRoadwayOptions(bookingDetail: any) {
  if (selectTransportationType(bookingDetail) === 'drop_and_pick') {
    return DROP_AND_PICK_ROADWAY_STATUS_OPTIONS;
  }

  return LIVE_LOAD_ROADWAY_STATUS_OPTIONS;
}

export function selectPerDiemStartDate(bookingDetail: any) {
  const perDiemStarts = bookingDetail?.tracking?.perDiemStarts as null | string;

  if (!perDiemStarts) {
    return null;
  }

  return new Date(perDiemStarts);
}

export function selectRoadwayStatus(bookingDetail: any): RoadwayStatus | undefined {
  if (bookingDetail?.tracking?.rdw_completed) {
    return 'completed';
  }

  if (bookingDetail?.tracking?.rdw_empty_pick_up) {
    return 'empty_picked_up';
  }

  if (bookingDetail?.tracking?.rdw_delivered) {
    return 'picked_up_ongoing';
  }

  if (bookingDetail?.tracking?.rdw_in_transit) {
    return 'dispatched';
  }

  if (bookingDetail?.tracking?.rdw_picked_up_date) {
    return 'picked_up';
  }

  return undefined;
}

export function selectLiveTrackingUrl(bookingDetail: any) {
  if (bookingDetail?.tracking?.live_tracking_url) {
    return bookingDetail?.tracking?.live_tracking_url as string;
  }

  return null;
}

export function selectEmptyPickUpDate(bookingDetail: any) {
  if (bookingDetail?.tracking?.rdw_empty_pick_up) {
    return bookingDetail?.tracking?.rdw_empty_pick_up as string;
  }

  return null;
}
export function selectRoadwayProgress(bookingDetail: any) {
  const roadwayStatus = selectRoadwayStatus(bookingDetail);

  if (!roadwayStatus || roadwayStatus === 'picked_up') {
    return 0;
  }
  if (roadwayStatus === 'dispatched') {
    return 50;
  }
  return 100;
}

export type BookingLifecycleStatus = 'booked' | 'ocean_voyage' | 'terminal' | 'roadway' | 'completed';

export function selectBookingLifecycleStatus(bookingDetail: any) {
  return bookingDetail?.bookingLifecycleStatus as BookingLifecycleStatus;
}

export function selectVesselLatitude(bookingDetail: any) {
  return bookingDetail?.tracking?.vesselLatitude as string | undefined;
}

export function selectVesselLongitude(bookingDetail: any) {
  return bookingDetail?.tracking?.vesselLongitude as string | undefined;
}

export function selectInvoiceId(bookingDetail: any) {
  return bookingDetail?.invoiceId as number | undefined;
}

export const OCEAN_BOOKING_LIFECYCLE_STATUS_OPTIONS: StatusOptions<BookingLifecycleStatus> = [
  { value: 'booked', title: 'Booked' },
  { value: 'ocean_voyage', title: 'Ocean Voyage' },
  { value: 'terminal', title: 'Termainal' },
  { value: 'roadway', title: 'Roadway' },
  { value: 'completed', title: 'Completed' }
];

export const BOOKING_LIFECYCLE_STATUS_OPTIONS = OCEAN_BOOKING_LIFECYCLE_STATUS_OPTIONS.filter((option) => option.value !== 'ocean_voyage');

export function selectFromLocation(bookingDetail: any) {
  return bookingDetail?.fromLocation?.locationType;
}

export function selectHasOceanVoyage(bookingDetail: any) {
  return selectFromLocation(bookingDetail) === 'ocean';
}

export function selectBookingLifecycleOptions(bookingDetail: any) {
  return selectHasOceanVoyage(bookingDetail) ? OCEAN_BOOKING_LIFECYCLE_STATUS_OPTIONS : BOOKING_LIFECYCLE_STATUS_OPTIONS;
}

export function selectLastUpdatedDate(bookingDetail: any) {
  return bookingDetail?.lastUpdated
    ? format(new Date(bookingDetail?.lastUpdated), 'dd MMM yyyy, HH:mm')
    : 'This booking has not been updated yet';
}

export function selectEtaDateString(bookingDetail: any) {
  return bookingDetail?.tracking?.eta as string | undefined;
}

export interface RateBreakDownItem {
  title: string;
  value: number | undefined;
}

export function selectRateBrakeDownItems(bookingDetail: any): RateBreakDownItem[] {
  return [
    { title: 'Transportation', value: bookingDetail?.transportationFee },
    { title: 'Fuel Surcharge', value: bookingDetail?.fuelSurchargeFee },
    { title: 'Drop & Pick', value: bookingDetail?.dropPickFee },
    { title: 'Chassis', value: bookingDetail?.chassisFee },
    { title: 'Toll', value: bookingDetail?.tollFee },
    { title: 'Residential Area', value: bookingDetail?.residentialFee },
    { title: 'Pre Pull', value: bookingDetail?.prePullFee },
    { title: 'Reefer', value: bookingDetail?.reeferFee },
    { title: 'Hazardous', value: bookingDetail?.hazardousFee },
    { title: 'Overweight', value: bookingDetail?.overweightFee }
  ];
}

export function selectRateBrakeDownTotal(bookingDetail: any): RateBreakDownItem {
  return { title: 'Total', value: bookingDetail?.total };
}

export function selectBookingId(bookingDetail: any) {
  return bookingDetail?.id as number | undefined;
}

import React from 'react';
import { Box } from '@mui/material';
import { FromToAddressCard } from 'sections/booking-list-detail/FromToAddressCard';
import { PerDiemStatus } from 'sections/booking-list-detail/PerDiemStatus';
import { RoadwayStatusStepper } from 'sections/booking-list-detail/RoadwayStatusStepper/RoadwayStatusStepper';
import { PickupDateScheduler } from 'sections/booking-list-detail/SchedulePickupButton/PickupDateScheduler';
import { selectTransportationType } from 'sections/booking-list-detail/selectors';

export interface RoadwayStatusPickedUpOngoingProps {
  bookingDetailData: any;
  reloadBookingDetail: () => Promise<void>;
}

export const RoadwayStatusPickedUpOngoing: React.FC<RoadwayStatusPickedUpOngoingProps> = ({ bookingDetailData, reloadBookingDetail }) => {
  const transportationType = selectTransportationType(bookingDetailData);

  return (
    <>
      <Box position="absolute" left={0} top={0} bottom={0} display="flex" flexDirection="column" gap="10px">
        <FromToAddressCard hideFrom from={bookingDetailData.fromLocation.terminalName} to={bookingDetailData.toLocation.address} />
        <Box my="auto">
          <PerDiemStatus bookingDetail={bookingDetailData} width="fit-content" />
          {transportationType === 'drop_and_pick' ? (
            <PickupDateScheduler bookingDetail={bookingDetailData} reloadBookingDetail={reloadBookingDetail} />
          ) : null}
        </Box>
      </Box>

      <RoadwayStatusStepper
        bookingDetail={bookingDetailData}
        position="absolute"
        right={0}
        top="50%"
        sx={{ transform: 'translateY(-50%)' }}
      />
    </>
  );
};

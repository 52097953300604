import React from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';

export interface ViewInvoiceButtonProps extends React.ComponentProps<typeof Box> {
  invoiceId?: number | null;
}

export const ViewInvoiceButton: React.FC<ViewInvoiceButtonProps> = ({ invoiceId, ...boxProps }) => {
  if (!invoiceId) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <a style={{ textDecoration: 'none', display: 'inline-block' }} href={`/billing-invoicing/${invoiceId}`}>
        <ButtonBase
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            backgroundColor: '#1890FF',
            padding: '9px 16px',
            borderRadius: '4px',
            boxShadow: 'box-shadow: 0px 2px 0px 0px #0000000B'
          }}
        >
          <Typography fontSize={14} fontWeight={400} color="#FFFFFF">
            See Invoice
          </Typography>
        </ButtonBase>
      </a>
    </Box>
  );
};

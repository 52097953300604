import { Box } from '@mui/material';
export interface StraightConnectorProps {
  progress?: number;
  icon?: JSX.Element;
}

export const StraightConnector: React.FC<StraightConnectorProps> = ({ progress = 0, icon }) => {
  return (
    <Box position="relative" flexGrow={1}>
      <Box
        height="2px"
        sx={{
          background: `linear-gradient(to right, #1890FF 0%, #1890FF ${progress}%, #E6F7FF ${progress}%, #E6F7FF)`
        }}
      />
      <Box
        position="absolute"
        color="#1890FF"
        bottom={1}
        sx={{
          transform: 'translateX(-50%)',
          left: `${progress}%`
        }}
      >
        {icon}
      </Box>
    </Box>
  );
};

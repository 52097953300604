import { Box } from '@mui/material';
import React from 'react';
import { VerticalStepper } from 'sections/booking-list-detail/VerticalStepper';
import { selectBookingLifecycleOptions, selectBookingLifecycleStatus } from 'sections/booking-list-detail/selectors';

export interface VerticalBookingStatusStepperProps extends React.ComponentProps<typeof Box> {
  bookingDetail: any;
}

export const VerticalBookingStatusStepper: React.FC<VerticalBookingStatusStepperProps> = ({ bookingDetail, sx, ...boxProps }) => {
  const steps = selectBookingLifecycleOptions(bookingDetail);
  const value = selectBookingLifecycleStatus(bookingDetail);

  return <VerticalStepper steps={steps} value={value} sx={sx} {...boxProps} />;
};

import React from 'react';
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer';

import Header from './Header';
import Content from './Content';

const styles = StyleSheet.create({
  page: {
    padding: 30
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column'
    }
  }
});

export interface PrintableInvoiceProps {
  data: {
    invoiceDetail: any;
    bookingDetail: any;
  };
}

export const PrintableInvoice: React.FC<PrintableInvoiceProps> = ({ data }) => {
  return (
    <Document title={`${data?.invoiceDetail?.id}`}>
      <Page size="A4" style={styles.page}>
        <Header list={data} />
        <View style={styles.container}>
          <Content list={data} />
        </View>
      </Page>
    </Document>
  );
};

import React from 'react';
import { Box } from '@mui/material';
import { TerminalStatusCard, TerminalStatusVariant } from 'sections/booking-list-detail/TerminalContainerStatus/TerminalStatusCard';

export interface TerminalContainerStatusProps {
  containerStatus: string;
  freightStatus: string;
  customsStatus: string;
  lastUpdated: string;
  tmlCustomReleasedDate: string;
  tmlFreightReleasedDate: string;
  tmlContainerAvailabilityDate: string;
}

const STATUS_MAP: Record<string, TerminalStatusVariant> = {
  Available: 'available',
  Released: 'available',
  'No Hold': 'available',
  'Pending Release': 'pending',
  'Info Pending': 'pending',
  'Not Available': 'pending',
  'Freight Hold': 'hold',
  'Terminal Hold': 'hold',
  'Customs Hold': 'hold'
};

export const TerminalContainerStatus: React.FC<TerminalContainerStatusProps> = ({
  containerStatus,
  freightStatus,
  customsStatus,
  lastUpdated,
  tmlCustomReleasedDate,
  tmlFreightReleasedDate,
  tmlContainerAvailabilityDate
}) => {
  const lastUpdatedDate = new Date(lastUpdated);
  const containerDate = new Date(tmlContainerAvailabilityDate);
  const freightDate = new Date(tmlFreightReleasedDate);
  const customsDate = new Date(tmlCustomReleasedDate);

  const containerVariant = STATUS_MAP[containerStatus];
  const freightVariant = STATUS_MAP[freightStatus];
  const customsVariant = STATUS_MAP[customsStatus];
  return (
    <Box
      borderRadius="4px"
      width="100%"
      sx={{
        boxShadow: '0px 9px 28px 8px #0000000D, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F',
        backgroundColor: '#FFFFFF'
      }}
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr"
    >
      <TerminalStatusCard
        title="Container Status"
        status={containerStatus}
        variant={containerVariant}
        borderRight="1px solid #F0F0F0"
        date={containerVariant === 'available' ? containerDate : lastUpdatedDate}
        dateLabel={containerVariant === 'available' ? 'Available as of' : 'Last update'}
      />
      <TerminalStatusCard
        title="Freight Status"
        status={freightStatus}
        variant={freightVariant}
        borderRight="1px solid #F0F0F0"
        date={freightVariant === 'available' ? freightDate : lastUpdatedDate}
        dateLabel={freightVariant === 'available' ? 'Released' : 'Last update'}
      />
      <TerminalStatusCard
        title="Customs Status"
        status={customsStatus}
        variant={customsVariant}
        date={customsVariant === 'available' ? customsDate : lastUpdatedDate}
        dateLabel={customsVariant === 'available' ? 'Released' : 'Last update'}
      />
    </Box>
  );
};

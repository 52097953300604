import React from 'react';
import { Map } from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { ROUTE_LAYER } from 'sections/booking-list-detail/MapBackground/useShowRoute';

export const useApplyMapLayerStyles = (map: React.MutableRefObject<Map | null>) => {
  const applyMapLayerStyles = React.useCallback(() => {
    map.current?.getStyle().layers.forEach((layer) => {
      if (layer.id === ROUTE_LAYER) return;
      if (layer.type === 'symbol') {
        map.current?.setPaintProperty(layer.id, `icon-opacity`, 0.5);
        map.current?.setPaintProperty(layer.id, `text-opacity`, 0.5);
      } else {
        map.current?.setPaintProperty(layer.id, `${layer.type}-opacity`, 0.5);
      }
    });
  }, [map]);

  return applyMapLayerStyles;
};

import React from 'react';
import {
  Box,
  Grid,
  Chip,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider
} from '@mui/material';

import MainCard from 'components/MainCard';
import LogoSection from 'components/logo';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { payInvoiceServ } from 'services/finance.service';
import { format } from 'date-fns';
import { PrintableInvoice } from 'sections/invoice/export-pdf/PrintableInvoice';

export interface InvoiceViewProps {
  invoiceDetail: any;
  bookingDetail: any;
}

export const InvoiceView: React.FC<InvoiceViewProps> = ({ invoiceDetail, bookingDetail }) => {
  const componentRef: React.Ref<HTMLDivElement> = React.useRef(null);
  const createdAt = new Date(invoiceDetail.createdAt);
  const dueDate = new Date(invoiceDetail.dueDate);

  const payInvoice = async (e: any, id: number) => {
    e.stopPropagation();
    const stripeUrlData = await payInvoiceServ({
      invoiceId: id
    });
    window.open(stripeUrlData.data.redirectUrl, '_self');
  };

  return (
    <MainCard>
      <Stack direction={'row'} justifyContent={'center'} sx={{ mb: 3 }}>
        {invoiceDetail.status !== 'paid' && (
          <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={(e) => payInvoice(e, invoiceDetail.id)}>
            Pay Now
          </Button>
        )}

        <PDFDownloadLink
          document={<PrintableInvoice data={{ invoiceDetail, bookingDetail }} />}
          fileName={`${invoiceDetail.invoiceNumber}.pdf`}
        >
          <Button variant="outlined" color="secondary">
            Download Invoice
          </Button>
        </PDFDownloadLink>
      </Stack>

      <MainCard content={false}>
        <Stack spacing={2.5}>
          <Box id="print" ref={componentRef}>
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                  sx={{ mx: '1rem', marginTop: '1rem' }}
                >
                  <Box>
                    <Stack direction="row" spacing={2}>
                      <LogoSection />
                      <Chip
                        label={invoiceDetail.status}
                        variant="light"
                        color={invoiceDetail.status === 'Closed' ? 'success' : invoiceDetail.status === 'Past Due' ? 'error' : 'primary'}
                        size="small"
                      />
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row">
                      <strong>#{invoiceDetail.invoiceNumber}</strong>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ borderWidth: 1 }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack spacing={1} sx={{ mx: '1rem', marginTop: '1rem' }}>
                  <Typography variant="h5">EZPORT INC</Typography>
                  <Typography color="secondary">304 S. Jones Blvd #8005</Typography>
                  <Typography color="secondary">Las Vegas, NV 89107</Typography>
                  <Typography color="secondary">billing@ezport.com</Typography>
                  <Typography color="secondary">(+1)-212-685-3090</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ borderWidth: 1 }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: 'flex-start', sm: 'space-between' }}>
                  <Stack sx={{ mx: '1rem', marginTop: '1rem' }} spacing={1}>
                    <Typography variant="h5">Customer</Typography>
                    <Stack direction="row" justifyContent={{ xs: 'space-between', sm: 'flex-start' }} spacing={1}>
                      <Typography color="secondary">Name: </Typography>
                      <Typography>{invoiceDetail.customerName ?? ''}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={{ xs: 'space-between', sm: 'flex-start' }} spacing={1}>
                      <Typography color="secondary">Adress: </Typography>
                      <Typography align="right">{invoiceDetail.billingAddress.address ?? ''}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={{ xs: 'space-between', sm: 'flex-start' }} spacing={1}>
                      <Typography color="secondary">Phone No: </Typography>
                      <Typography>{invoiceDetail.billingAddress.phone ?? ''}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={{ xs: 'space-between', sm: 'flex-start' }} spacing={1}>
                      <Typography color="secondary">Email: </Typography>
                      <Typography>{invoiceDetail.billingAddress.email ?? ''}</Typography>
                    </Stack>
                  </Stack>

                  <Stack sx={{ mx: '1rem', marginTop: '1rem' }} spacing={1}>
                    <Typography variant="h5">Order Details</Typography>
                    <Stack direction="row" justifyContent={'space-between'} spacing={1}>
                      <Typography color="secondary">Invoice Date: </Typography>
                      <Typography>{format(createdAt, 'dd MM yyy')}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={'space-between'} spacing={1}>
                      <Typography color="secondary">Terms: </Typography>
                      <Typography>{invoiceDetail.paymentTermsLabel}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={'space-between'} spacing={1}>
                      <Typography color="secondary">Due Date: </Typography>
                      <Typography>{format(dueDate, 'dd MM yyy')}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent={'space-between'} spacing={1}>
                      <Typography color="secondary">Booking No: </Typography>
                      <Typography>{bookingDetail.id}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Quantity</TableCell>
                        <TableCell align="left">Rate</TableCell>
                        <TableCell align="left">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceDetail?.lineItems.map((row: any, index: any) => (
                        <TableRow
                          key={row.expense_id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, verticalAlign: 'text-top' }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell style={{ minWidth: '20rem' }}>
                            {/* {row.description} */}
                            <Typography
                              sx={{
                                marginBottom: row.description === 'Transportation Rate' ? 3 : 0
                              }}
                            >
                              {row.description === 'Transportation Rate' ? 'Transportation' : row.description}
                            </Typography>
                            {row.description === 'Transportation Rate' && (
                              <Stack spacing={1}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                  <Typography color="secondary">Booking:</Typography>
                                  <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                                    {bookingDetail.bookingNo}
                                  </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                  <Typography color="secondary">Container Size & Id:</Typography>
                                  <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                                    {bookingDetail.containerSize + ' - ' + bookingDetail.containerNo}
                                  </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                  <Typography color="secondary">B/L:</Typography>
                                  <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                                    {bookingDetail.billOfLading}
                                  </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                  <Typography color="secondary">Reference</Typography>
                                  <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                                    {bookingDetail.referenceNo}
                                  </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                  <Typography color="secondary">From:</Typography>
                                  <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                                    {bookingDetail.fromLocation.terminalAddress
                                      ? bookingDetail.fromLocation.terminalAddress
                                      : bookingDetail.fromLocation.yardAddress
                                      ? bookingDetail.fromLocation.yardAddress
                                      : bookingDetail.fromLocation.rampAddress}
                                  </Typography>
                                </Stack>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                  <Typography color="secondary">To:</Typography>

                                  <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                                    {bookingDetail.toLocation.terminalAddress
                                      ? bookingDetail.toLocation.terminalAddress
                                      : bookingDetail.toLocation.yardAddress
                                      ? bookingDetail.toLocation.yardAddress
                                      : bookingDetail.toLocation.rampAddress}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}
                          </TableCell>
                          <TableCell align="center">{row.quantity}</TableCell>
                          <TableCell align="center">${row.rate}</TableCell>
                          <TableCell align="center">${Number(row.item_total).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ borderWidth: 1 }} />
              </Grid>
              <Grid item xs={12} sm={6} md={8} sx={{ backgroundColor: '#FAFAFA', paddingBottom: 2 }}></Grid>
              {/* ----- */}

              <Grid item xs={12} sm={6} md={4} sx={{ backgroundColor: '#FAFAFA', paddingBottom: 2 }}>
                <Stack spacing={2} sx={{ mx: 5 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1" color={'#1890FF'}>
                      Total:
                    </Typography>
                    <Typography color={'#1890FF'} variant="subtitle1" fontWeight={600}>
                      ${bookingDetail.total}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </MainCard>
    </MainCard>
  );
};

import Box from '@mui/material/Box';
import React from 'react';

export interface GaugeIndicatorProps
  extends Omit<React.ComponentProps<typeof Box>, 'position' | 'width' | 'height' | 'display' | 'justifyContent' | 'alignItems'> {
  progress: number;
  size: number;
  totalColor: string;
  progressColor: string;
}

export const GaugeIndicator: React.FC<GaugeIndicatorProps> = ({ progress, size, totalColor, progressColor, children, ...boxProps }) => {
  const width = size;
  const height = (size * 66) / 70;
  return (
    <Box {...boxProps} position="relative" width={width} height={height} display="flex" justifyContent="center" alignItems="center">
      <Box width={width}>{children}</Box>
      <Box position="absolute" width={width} height={height} top={0} left={0}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
          <path stroke={totalColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.41" d={getD(1, size)} />
          <path stroke={progressColor} strokeLinecap="round" strokeWidth="4" d={getD(progress, size)} />
        </svg>
      </Box>
    </Box>
  );
};

type Vector = [number, number];
type Matrix = [Vector, Vector];

const cos = Math.cos;
const sin = Math.sin;
const π = Math.PI;

const f_matrix_times = ([[a, b], [c, d]]: Matrix, [x, y]: Vector): Vector => [a * x + b * y, c * x + d * y];
const f_rotate_matrix = (x: number): Matrix => [
  [cos(x), -sin(x)],
  [sin(x), cos(x)]
];
const f_vec_add = ([a1, a2]: Vector, [b1, b2]: Vector) => [a1 + b1, a2 + b2];

function getD(value: number, size: number) {
  const startAngle = 125;
  const endAngle = 290;
  const stopAngle = endAngle * value;

  //   returns a SVG path element that represent a ellipse.
  //   cx,cy → center of ellipse
  //   rx,ry → major minor radius
  //   t1 → start angle, in radian.
  //   Δ → angle to sweep, in radian. positive.
  //   φ → rotation on the whole, in radian
  //   URL: SVG Circle Arc http://xahlee.info/js/svg_circle_arc.html
  //   Version 2019-06-19

  const cx = size / 2;
  const cy = size / 2;
  const rx = (size * 13) / 28;
  const ry = (size * 13) / 28;
  const t1 = (startAngle / 180) * π;
  let Δ = (stopAngle / 180) * π;
  const φ = 0;

  Δ = Δ % (2 * π);
  const rotMatrix = f_rotate_matrix(φ);
  const [sX, sY] = f_vec_add(f_matrix_times(rotMatrix, [rx * cos(t1), ry * sin(t1)]), [cx, cy]);
  const [eX, eY] = f_vec_add(f_matrix_times(rotMatrix, [rx * cos(t1 + Δ), ry * sin(t1 + Δ)]), [cx, cy]);
  const fA = Δ > π ? 1 : 0;
  const fS = Δ > 0 ? 1 : 0;

  return 'M ' + sX + ' ' + sY + ' A ' + [rx, ry, (φ / (2 * π)) * 360, fA, fS, eX, eY].join(' ');
}

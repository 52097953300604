import { Typography } from '@mui/material';
import React from 'react';

export interface InputFieldLabelProps {
  disabled?: boolean;
}

export const InputFieldLabel: React.FC<React.PropsWithChildren<InputFieldLabelProps>> = ({ disabled = false, children }) => {
  return <Typography color={disabled ? '#bfbfbf' : undefined}>{children}</Typography>;
};

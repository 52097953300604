import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import BookingListDetail from 'pages/booking-list/BookingListDetail';
import InvoicePage from 'pages/invoice';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const NewBookingPage = Loadable(lazy(() => import('pages/new-booking/NewBookingPage')));
const BookingListPage = Loadable(lazy(() => import('pages/booking-list')));
const QuoteListPage = Loadable(lazy(() => import('pages/quote-list')));
const AdressBookPage = Loadable(lazy(() => import('pages/adress-book')));
const BillingInvoicingPage = Loadable(lazy(() => import('pages/billing-invoicing/billingInvoicing')));
const CompanyPage = Loadable(lazy(() => import('pages/company/company')));
const ProfilePage = Loadable(lazy(() => import('pages/profile')));
const UsersPage = Loadable(lazy(() => import('pages/users')));
const Notifications = Loadable(lazy(() => import('pages/notifications')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'sample-page',
          element: <SamplePage />
        },
        {
          path: 'new-booking',
          element: <NewBookingPage />
        },
        {
          path: 'new-booking/:id',
          element: <NewBookingPage />
        },
        {
          path: 'get-rates-web',
          element: <NewBookingPage />
        },
        {
          path: 'booking-list',
          element: <BookingListPage />
        },
        {
          path: 'quote-list',
          element: <QuoteListPage />
        },
        {
          path: 'booking-list/:id',
          element: <BookingListDetail />
        },
        {
          path: 'adress-book',
          element: <AdressBookPage />
        },
        {
          path: 'billing-invoicing',
          element: <BillingInvoicingPage />
        },
        {
          path: 'billing-invoicing/:invoiceId',
          element: <InvoicePage />
        },
        {
          path: 'profile',
          element: <ProfilePage />
        },
        {
          path: 'company',
          element: <CompanyPage />
        },
        {
          path: 'users',
          element: <UsersPage />
        },
        {
          path: 'notifications',
          element: <Notifications />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;

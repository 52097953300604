import React from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';

export interface LiveTrackingUrlButtonProps extends React.ComponentProps<typeof Box> {
  url?: string | null;
}

export const LiveTrackingUrlButton: React.FC<LiveTrackingUrlButtonProps> = ({ url, ...boxProps }) => {
  if (!url) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <a style={{ textDecoration: 'none', display: 'inline-block' }} href={url} target={'_blank'} rel="noopener noreferrer">
        <ButtonBase
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            padding: '4px 12px',
            borderRadius: '4px',
            boxShadow: '0px 9px 28px 8px #0000000D, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F'
          }}
        >
          <Box sx={{ width: '6px', height: '6px', borderRadius: '50%', backgroundColor: '#FF4D4F' }} />
          <Typography fontSize={14} fontWeight={400} color="#FF4D4F">
            Live
          </Typography>
        </ButtonBase>
      </a>
    </Box>
  );
};

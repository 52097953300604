import { Box, ButtonBase, Typography } from '@mui/material';
import { EditIcon } from 'components/icons/EditIcon';
import { EventUpcomingIcon } from 'components/icons/EventUpcomingIcon';
import { format } from 'date-fns';
import React from 'react';

export interface PickupScheduledCardProps {
  value: Date;
  onEditClick: () => void;
}

export const PickupScheduledCard: React.FC<PickupScheduledCardProps> = ({ value, onEditClick }) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 9px 28px 8px #0000000D, box-shadow: 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F',
        borderRadius: '4px'
      }}
    >
      <Box
        sx={{
          width: '100%',
          padding: '9px 16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          color: '#FFFFFF',
          backgroundColor: '#1890FF',
          border: '1px solid #1890FF',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px'
        }}
      >
        <EventUpcomingIcon height="14px" />
        <Typography fontSize={14} fontWeight={400}>
          Pickup Scheduled
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#262626',
          backgroundColor: '#FFFFFF',
          border: '1px solid #D9D9D9',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px'
        }}
      >
        <Box padding="9px 16px" borderRight="1px solid #D9D9D9" display="flex" gap="8px">
          <Typography fontSize={14} fontWeight={400}>
            {format(value, "dd MMM, yy''")}
          </Typography>
          <Typography
            fontSize={12}
            fontWeight={400}
            color="#1890FF"
            sx={{
              backgroundColor: '#E6F7FF',
              padding: '1px 8px'
            }}
          >
            {format(value, 'KK:mm aaa')}
          </Typography>
        </Box>

        <ButtonBase onClick={onEditClick}>
          <Box px="12px">
            <EditIcon />
          </Box>
        </ButtonBase>
      </Box>
    </Box>
  );
};

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserOutlined, TeamOutlined, GoldOutlined, FileTextOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  GoldOutlined,
  TeamOutlined,
  FileTextOutlined,
  UserOutlined
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const account: NavItemType = {
  id: 'group-account',
  title: <FormattedMessage id="My Account" />,
  icon: icons.GoldOutlined,
  type: 'group',
  children: [
    {
      id: 'billing-invoicing',
      title: <FormattedMessage id="Billing & Invoicing" />,
      type: 'item',
      url: '/billing-invoicing',
      icon: icons.FileTextOutlined,
      breadcrumbs: true
    },
    {
      id: 'profile',
      title: <FormattedMessage id="Profile" />,
      type: 'item',
      url: '/profile',
      icon: icons.UserOutlined,
      breadcrumbs: true
    },
    {
      id: 'users',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      icon: icons.TeamOutlined,
      url: '/users'
    }
  ]
};

export default account;

import { Box } from '@mui/material';
import { ThresholdDateProgressCard } from 'components/ThresholdDateProgressCard/ThresholdDateProgressCard';
import { KeyboardEnterIcon } from 'components/icons/KeyboardEnterIcon';
import { add } from 'date-fns';
import React from 'react';

export interface DemurrageStatusProps extends React.ComponentProps<typeof Box> {
  lastFreeDay: string;
}

const DEFAULT_DEMURRAGE_DAYS = 7;

const CARD_TITLE = {
  before: 'Demurrage Starts',
  after: 'Demurrage Started'
};

const DATE_LABEL = {
  before: 'Last Free Day',
  after: 'Last Free Day'
};

export const DemurrageStatus: React.FC<DemurrageStatusProps> = ({ lastFreeDay: lastFreeDayString, ...boxProps }) => {
  const lastFreeDay = new Date(lastFreeDayString);
  const startDate = add(lastFreeDay, { days: -DEFAULT_DEMURRAGE_DAYS });

  return (
    <ThresholdDateProgressCard
      cardTitle={CARD_TITLE}
      dateLabel={DATE_LABEL}
      startDate={startDate}
      endDate={lastFreeDay}
      icon={<KeyboardEnterIcon />}
      {...boxProps}
    />
  );
};

import { useEffect } from 'react';

import { Box, useTheme } from '@mui/material';

import { useNavigate } from 'react-router';
import { dispatch, useSelector } from 'store';
import { getWallet } from 'store/reducers/user/wallet';

import LoadingButton from 'components/@extended/LoadingButton';

const Balance = () => {
  const theme = useTheme();
  const buttonBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getWallet());
  }, []);

  const data = useSelector((state: any) => state.walletReducer);
  const handleClick = () => {
    navigate('/billing-invoicing');
  };

  return (
    <Box sx={{ flexShrink: 1, lineHeight: 1, height: 44 }}>
      <LoadingButton
        sx={{
          width: 'max-content',
          color: 'text.primary',
          height: 44,
          bgcolor: buttonBackColor,
          '& .MuiLoadingButton-loadingIndicator': {
            color: 'black !important'
          },
          '& .anticon': {
            py: 0.6
          }
        }}
        color="secondary"
        variant="light"
        aria-label="open profile"
        aria-haspopup="true"
        onClick={handleClick}
        loading={data.status === 'loading' || data.status === 'idle'}
      >
        {/* <WalletOutlined /> */}
        {data.status === 'success' && (
          <Box>
            <p style={{ fontSize: 9, fontWeight: '500', margin: 0, color: '#262626' }}>Available Credit</p>
            <span style={{ fontWeight: '400', fontSize: 14, color: '#262626' }}>
              {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.wallet?.creditLimit ?? 0)}
            </span>
          </Box>
        )}
      </LoadingButton>
    </Box>
  );
};

export default Balance;

export default function ShipIcon({ color, ...props }: any = { color: '#1890FF' }) {
  return (
    <svg width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_70_18746)">
        <path
          d="M0 10.7744C0.145023 10.4951 0.390933 10.4427 0.68098 10.4434C2.87525 10.4484 5.06952 10.4459 7.26378 10.4459C14.863 10.4459 22.4622 10.4459 30.0608 10.4459C30.1718 10.4459 30.2822 10.4427 30.3931 10.4471C30.8749 10.4667 31.052 10.7788 30.8188 11.2025C30.0817 12.5412 29.3446 13.8798 28.6049 15.2165C27.6087 17.0186 26.1793 18.3553 24.2996 19.204C23.0789 19.7558 21.7932 19.9979 20.4597 19.9985C15.2558 20.0004 10.0526 20.0004 4.84883 19.9985C3.59028 19.9985 2.81976 19.4329 2.41937 18.2311C1.6539 15.9341 0.889057 13.637 0.121694 11.3412C0.0907973 11.2498 0.0409849 11.1647 0 11.0764C0 10.9755 0 10.8753 0 10.7744ZM6.84069 13.4813C6.34825 13.4851 5.96677 13.8773 5.97497 14.3729C5.98253 14.847 6.37284 15.2348 6.84511 15.238C7.32558 15.2411 7.73164 14.8351 7.73101 14.3533C7.73038 13.8659 7.33377 13.4775 6.84069 13.4813ZM11.3635 14.3603C11.3661 13.8659 10.9795 13.4794 10.4852 13.4807C10.0066 13.4819 9.61884 13.8602 9.60812 14.3357C9.59677 14.818 9.99275 15.2291 10.4757 15.238C10.9581 15.2462 11.361 14.8483 11.3635 14.3603ZM14.131 13.4813C13.6404 13.4794 13.2413 13.8741 13.2438 14.3584C13.2463 14.8369 13.6606 15.2443 14.1373 15.2373C14.6108 15.2304 14.9967 14.842 15.0024 14.3672C15.0087 13.8716 14.6259 13.4832 14.1303 13.4813H14.131Z"
          fill={color}
        />
        <path
          d="M15.8361 9.9822H3.47065C3.46435 9.8914 3.45237 9.80565 3.45237 9.71926C3.4511 8.81318 3.45047 7.9071 3.45237 7.00165C3.453 6.50037 3.59739 6.35661 4.0993 6.35661C7.26018 6.35598 10.4204 6.35913 13.5813 6.35157C13.8915 6.35093 14.0851 6.45497 14.2232 6.73871C14.7163 7.75073 15.227 8.75391 15.7302 9.76088C15.7611 9.82267 15.7895 9.88509 15.8361 9.9822Z"
          fill={color}
        />
        <path d="M5.75391 2.73242H8.90029V5.86871H5.75391V2.73242Z" fill={color} />
        <path d="M8.8941 1.78883H5.75781V0H8.8941V1.78883Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_70_18746">
          <rect width="30.9209" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { Box } from '@mui/system';
import React from 'react';
import { BookingInRoadwayStatus } from 'sections/booking-list-detail/BookingInRoadwayStatus/BookingInRoadwayStatus';
import { DemurrageStatus } from 'sections/booking-list-detail/DemurrageStatus/DemurrageStatus';
import { PickupDateCard } from 'sections/booking-list-detail/PickupDateCard/PickupDateCard';
import { selectBookingLifecycleStatus } from 'sections/booking-list-detail/selectors';
import { ShowVesselLocationButton } from 'sections/booking-list-detail/ShowVesselLocationButton';
import { TerminalContainerStatus } from 'sections/booking-list-detail/TerminalContainerStatus/TerminalContainerStatus';
import VoyageEta from 'sections/booking-list-detail/VoyageEta';

export interface BookingDetailDashboardProps {
  bookingDetailData: any;
  reloadBookingDetail: () => Promise<void>;
}

export const BookingDetailDashboard: React.FC<BookingDetailDashboardProps> = ({ bookingDetailData, reloadBookingDetail }) => {
  const bookingLifecycleStatus = selectBookingLifecycleStatus(bookingDetailData);

  return (
    <>
      {bookingLifecycleStatus === 'ocean_voyage' ? (
        <Box display="flex" flexDirection="column" gap="1rem">
          <VoyageEta
            etaDate={bookingDetailData?.tracking?.eta}
            inboundVoyage={bookingDetailData?.tracking?.inboundVoyageNumber}
            outboundVoyage={bookingDetailData?.tracking?.outboundVoyage}
            vessel={bookingDetailData?.tracking?.vesselName || '-'}
            containerNo={bookingDetailData?.containerNo || '-'}
            containerType={bookingDetailData?.containerSize}
          />
          <ShowVesselLocationButton bookingDetailData={bookingDetailData} />
        </Box>
      ) : null}
      {bookingLifecycleStatus === 'terminal' && (
        <Box display="grid" gap="20px">
          <TerminalContainerStatus
            containerStatus={bookingDetailData.tracking?.containerStatus}
            freightStatus={bookingDetailData.tracking?.freightStatus}
            customsStatus={bookingDetailData.tracking?.customsStatus}
            tmlCustomReleasedDate={bookingDetailData.tracking?.tmlCustomReleasedDate}
            tmlFreightReleasedDate={bookingDetailData.tracking?.tmlFreightReleasedDate}
            tmlContainerAvailabilityDate={bookingDetailData.tracking?.tmlContainerAvailabilityDate}
            lastUpdated={bookingDetailData.lastUpdated}
          />
          <Box ml="auto" display="grid" gap="20px">
            {bookingDetailData.tracking?.lastFreeDay ? <DemurrageStatus lastFreeDay={bookingDetailData.tracking?.lastFreeDay} /> : null}
            {bookingDetailData.tracking?.scheduledPickupDate ? (
              <PickupDateCard date={bookingDetailData.tracking?.scheduledPickupDate} />
            ) : null}
          </Box>
        </Box>
      )}

      {bookingLifecycleStatus === 'roadway' && (
        <BookingInRoadwayStatus bookingDetailData={bookingDetailData} reloadBookingDetail={reloadBookingDetail} />
      )}
    </>
  );
};

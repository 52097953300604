/* eslint-disable @typescript-eslint/no-unused-expressions */
import endpoints from 'constants/endpoints';
import axiosServices from 'utils/axios';
import { errorMessageSnackbar } from 'utils/snackbar-message';

export const getNotificationsListService = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.getNotificationsList, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const markedAllReadNotificationsService = async (): Promise<any> => {
  try {
    return (await axiosServices.post(endpoints.markedAllReadNotifications)).data;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const markedMultipleReadNotificationsService = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.markedMultipleNotifications, data)).data;
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const presetFiltersNotificationsService = async (): Promise<any> => {
  return (await axiosServices.get(endpoints.presetFiltersNotifications)).data;
};

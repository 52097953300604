/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { getUserWalletServ } from 'services/finance.service';

export interface WalletInfo {
  balance: number;
  creditLimit: number;
}

const initialState: { wallet: WalletInfo; status: 'idle' | 'loading' | 'success' | 'failed' } = {
  status: 'idle',
  wallet: {
    balance: 0,
    creditLimit: 0
  }
};

export const getWallet = createAsyncThunk('profile/getWallet', async (_, thunkAPI) => {
  try {
    const response = await getUserWalletServ();
    return response?.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

const walletReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getWallet.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getWallet.fulfilled, (state, action) => {
      state.status = 'success';
      state.wallet = action.payload;
    })
    .addCase(getWallet.rejected, (state, action) => {
      state.status = 'failed';
    });
});

export default walletReducer;

import { Box } from '@mui/material';

export interface WavyConnectorProps {
  progress?: number;
  icon?: JSX.Element;
}

export const WavyConnector: React.FC<WavyConnectorProps> = ({ progress = 0, icon }) => {
  return (
    <Box flexGrow={2} height="20px" position="relative">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 542 9" fill="none">
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stopColor="#1890FF" />
            <stop offset={`${progress}%`} stopColor="#1890FF" />
            <stop offset={`${progress}%`} stopColor="#E6F7FF" />
            <stop offset="100%" stopColor="#E6F7FF" />
          </linearGradient>
        </defs>
        <path
          stroke="url(#gradient)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 4.667c7.5-4 15-4 22.5 0s15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0 15-4 22.5 0 15 4 22.5 0"
        />
      </svg>
      <Box
        position="absolute"
        color="#1890FF"
        bottom={10}
        sx={{
          transform: 'translateX(-50%)',
          left: `${progress}%`
        }}
      >
        {icon}
      </Box>
    </Box>
  );
};

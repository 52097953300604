import { useCallback, useEffect, useState } from 'react';
import BookingInformation from 'sections/booking-list-detail/BookingInformation';
import RateBreakdown from 'sections/booking-list-detail/RateBreakdown';
import { Box, Button, Grid, Stack, useMediaQuery } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import BookingActivityPanel from 'sections/booking-list-detail/booking-activity-panel';
import MainCard from 'components/MainCard';
import { getBookingListDetailService } from 'services/bookingList.service';
import Loader from 'components/Loader';
import { useTheme } from '@mui/material/styles';
import VoyageInformations from 'sections/booking-list-detail/VoyageInformation';
import { useNavigate, useParams } from 'react-router';
import { lifecycleSteps } from 'constants/constants';
import DeleteModal from 'components/Alert/DeleteModal';
import { dispatch } from 'store';
import { deleteBooking } from 'store/reducers/bookingList';
import Instructions from 'sections/booking-list-detail/Instructions';
import { getMyRoles } from 'services/profile.service';
import { updateRatesInstruction } from 'services/getRates.service';
import { BookingStatusStepper } from 'sections/booking-list-detail/BookingStatusStepper/BookingStatusStepper';
import { BookingVisualizedStatus } from 'sections/booking-list-detail/BookingVisualizedStatus/BookingVisualizedStatus';
import { selectBookingLifecycleStatus } from 'sections/booking-list-detail/selectors';
import { BookingDetailWithBackground } from 'sections/booking-list-detail/BookingDetailWithBackground/BookingDetailWithBackground';

function BookingListDetail() {
  const bookingDetailID = useParams<any>().id;
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [bookingDetailData, setBookingDetailData] = useState<any>(null);
  const [userAdmin, setUserAdmin] = useState<any>(false);
  const [instLoading, setInstLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [lifecycleStepsFiltered, setLifecycleStepsFiltered] = useState(lifecycleSteps);

  useEffect(() => {
    getMyRoles().then((res) => {
      for (let i in res.roles) {
        if (res.roles[i].name === 'user_admin' || res.roles[i].name === 'super_admin') {
          setUserAdmin(true);
        }
      }
    });
  }, []);

  const fetchDetailData = useCallback(async () => {
    setLoading(true);
    const data = {
      trackingNo: bookingDetailID
    };
    const res = await getBookingListDetailService(data);
    if (res.data?.fromLocation?.locationType !== 'ocean') {
      let filtered = lifecycleSteps.filter((step) => step.key !== 'ocean_voyage');
      setLifecycleStepsFiltered(filtered);
    }
    setBookingDetailData(res.data);
    setLoading(false);
  }, [bookingDetailID]);

  useEffect(() => {
    if (bookingDetailID) {
      fetchDetailData();
    }
  }, [bookingDetailID, fetchDetailData]);

  const deleteBookingHandler = (id: number) => {
    dispatch(deleteBooking(id)).finally(() => {
      navigate('/booking-list');
    });
  };

  const handleCloseDeleteModal = async (isDelete?: boolean) => {
    if (isDelete) {
      await deleteBookingHandler(bookingDetailData.id);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const updateRatesInst = async (data: any) => {
    setInstLoading(true);
    return await handleUpdateRatesInstruction(`/${bookingDetailData.id}/instruction`, data);
  };

  const handleUpdateRatesInstruction = async (param: string, data: any) => {
    const response: any = await updateRatesInstruction(param, data);
    if (response) {
      fetchDetailData();
    }
    setInstLoading(false);
    return response;
  };

  const bookingLifecycleStatus = selectBookingLifecycleStatus(bookingDetailData);
  return loading ? (
    <Loader />
  ) : (
    <>
      <Grid>
        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
          <div style={{ display: 'flex' }}>
            <div onClick={() => navigate('/booking-list')} style={{ marginRight: '1rem', cursor: 'pointer' }}>
              {/* @ts-expect-error */}
              <ArrowLeftOutlined />
            </div>
            <strong>Booking #{bookingDetailID}</strong>
          </div>
          {bookingLifecycleStatus === 'booked' && (
            <Box display="flex" gap="8px">
              <Button
                variant="contained"
                color="error"
                size="small"
                style={{ marginRight: '1rem' }}
                onClick={() => handleCloseDeleteModal()}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid container justifyContent={'space-between'} style={{ marginTop: '0.3rem' }} spacing={1}>
        <Grid item xs={12} md={8} lg={9}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <BookingInformation bookingDetailData={bookingDetailData} />
            {userAdmin && (
              <Instructions isLoading={instLoading} onUpdate={(data) => updateRatesInst(data)} bookingDetailData={bookingDetailData} />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <RateBreakdown bookingDetailData={bookingDetailData} />
        </Grid>
      </Grid>
      {/* Card&Cost section is hidden, we want no one to see it for now */}
      {/* <Grid container style={{ marginTop: '1rem' }} spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          {userAdmin && <AdditionalServices bookingDetailData={bookingDetailData} />}
        </Grid>
      </Grid> */}
      {matchDownSM ? null : (
        <MainCard style={{ marginTop: '1rem' }} content={false}>
          <BookingStatusStepper steps={lifecycleStepsFiltered} activeStep={bookingLifecycleStatus} />
          <Grid container>
            <Grid item xs={4}>
              <BookingActivityPanel bookingDetailData={bookingDetailData} />
            </Grid>
            <Grid item xs={8} sx={{ position: 'relative' }}>
              <BookingDetailWithBackground bookingDetailData={bookingDetailData} reloadBookingDetail={fetchDetailData} />
            </Grid>
          </Grid>

          <BookingVisualizedStatus bookingDetail={bookingDetailData} />
        </MainCard>
      )}
      {matchDownSM ? (
        <Grid container style={{ marginTop: '0.2rem' }} spacing={1}>
          <Grid item xs={12}>
            <VoyageInformations bookingDetailData={bookingDetailData} />
          </Grid>
          <Grid item xs={12}>
            <BookingActivityPanel bookingDetailData={bookingDetailData} />
          </Grid>
        </Grid>
      ) : null}

      <DeleteModal title={bookingDetailID} open={openDeleteModal} handleClose={handleCloseDeleteModal} />
    </>
  );
}

export default BookingListDetail;

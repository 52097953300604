export default function FinishFlagIcon({ color, ...props }: any = { color: '#1890FF' }) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.1875 6.62402H12.1875V4.41699C12.1875 4.07129 11.9082 3.79199 11.5625 3.79199H3.59375V3.01074C3.59375 2.9248 3.52344 2.85449 3.4375 2.85449H2.34375C2.25781 2.85449 2.1875 2.9248 2.1875 3.01074V18.3232C2.1875 18.4092 2.25781 18.4795 2.34375 18.4795H3.4375C3.52344 18.4795 3.59375 18.4092 3.59375 18.3232V13.167H8.4375V15.374C8.4375 15.7197 8.7168 15.999 9.0625 15.999H17.1875C17.5332 15.999 17.8125 15.7197 17.8125 15.374V7.24902C17.8125 6.90332 17.5332 6.62402 17.1875 6.62402Z"
        fill={color}
      />
    </svg>
  );
}

export default function TerminalIcon({ color, ...props }: any = { color: '#1890FF' }) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.4878 7.40272L16.9897 4.07188C16.9129 3.96481 16.7882 3.90255 16.6566 3.90533H9.33732L6.123 0.699393C6.0756 0.640656 6.01185 0.597165 5.93973 0.574487H5.88973H5.88991C5.8191 0.554598 5.74419 0.554598 5.67337 0.574487L1.50981 2.23991C1.34067 2.30589 1.23546 2.4756 1.25163 2.65627V9.31796C1.25163 9.54788 1.43807 9.73432 1.66799 9.73432C1.89791 9.73432 2.08434 9.54788 2.08434 9.31796V8.06889H5.41519V19.7269H2.5007C2.27078 19.7269 2.08434 19.9133 2.08434 20.1432C2.08434 20.3731 2.27078 20.5596 2.5007 20.5596H12.4932C12.7232 20.5596 12.9096 20.3731 12.9096 20.1432C12.9096 19.9133 12.7232 19.7269 12.4932 19.7269H9.57875V8.06889H15.4077V11.8161C15.4077 11.9265 15.4516 12.0325 15.5297 12.1105C15.6077 12.1886 15.7137 12.2325 15.8241 12.2325C16.1553 12.2325 16.4732 12.364 16.7073 12.5983C16.9415 12.8325 17.0731 13.1503 17.0731 13.4815C17.0731 13.8127 16.9415 14.1306 16.7073 14.3647C16.4731 14.5989 16.1553 14.7305 15.8241 14.7305C15.4929 14.7305 15.175 14.5989 14.9408 14.3647C14.7067 14.1305 14.575 13.8127 14.575 13.4815C14.575 13.2516 14.3886 13.0651 14.1587 13.0651C13.9288 13.0651 13.7423 13.2516 13.7423 13.4815C13.7425 14.0094 13.9431 14.5175 14.3037 14.9032C14.6645 15.2887 15.1579 15.5228 15.6847 15.5583C16.2114 15.5936 16.7321 15.4274 17.1408 15.0934C17.5498 14.7596 17.8165 14.2828 17.8871 13.7596C17.9578 13.2364 17.8271 12.7059 17.5213 12.2756C17.2156 11.8451 16.7578 11.547 16.2405 11.4414V8.06889H19.155C19.3126 8.06889 19.4568 7.97986 19.5275 7.83878C19.5979 7.69771 19.5827 7.52893 19.4881 7.40272H19.4878ZM8.74585 16.8124L6.52242 15.1469L8.74585 13.4815V16.8124ZM6.24772 14.3142V10.9834L8.47115 12.6488L6.24772 14.3142ZM8.47115 17.6451L6.24772 19.3105V15.9797L8.47115 17.6451ZM6.52266 10.1507L8.74609 8.48525V11.8161L6.52266 10.1507ZM6.24775 1.99866L8.15461 3.90552H6.24775V1.99866ZM2.08419 2.93953L5.41504 1.60719V3.90552H2.08419V2.93953ZM7.91318 8.06904L6.24775 9.3181V8.06904H7.91318ZM7.08046 19.727L8.74589 18.4779V19.727H7.08046Z"
        fill={color}
      />
    </svg>
  );
}

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

export interface InfoFieldProps {
  label: string;
  value: string;
}

export const InfoField: React.FC<InfoFieldProps> = ({ label, value }) => {
  return (
    <Box>
      <Typography fontSize={14} fontWeight={400} color="#8C8C8C">
        {label}
      </Typography>
      <Typography fontSize={14} fontWeight={400} color="#262626">
        {value}
      </Typography>
    </Box>
  );
};

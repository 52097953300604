/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { JSONTryParse } from 'helpers/Utils';
import { getMyProfile } from 'services/profile.service';

export interface WalletInfo {
  balance: number;
  creditLimit: number;
}
export interface ProfileData {
  roles: string[];
  id: number;
  uniqueId: string;
  username: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  company: Company;
}

export interface Company {
  id: number;
  companyName: string;
  address: string;
}

const initialState: { profile: ProfileData; status: 'idle' | 'loading' | 'success' | 'failed' } = {
  status: 'idle',
  profile: JSONTryParse(localStorage.getItem('profileData') || '{}')
};

export const getProfile = createAsyncThunk('profile/getProfile', async (_, thunkAPI) => {
  try {
    const response = await getMyProfile();
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeProfile = createAction('profile/removeProfile');

const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProfile.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getProfile.fulfilled, (state, action) => {
      state.status = 'success';
      state.profile = action.payload;
      localStorage.setItem('profileData', JSON.stringify(action.payload));
    })
    .addCase(getProfile.rejected, (state, action) => {
      state.status = 'failed';
    })
    .addCase(removeProfile, (state) => {
      state.status = 'idle';
      state.profile = {} as ProfileData;
      localStorage.removeItem('profileData');
    });
});

export default profileReducer;

import { defaultDateFormat } from 'constants/constants';
import { format } from 'date-fns';
// import { formatDateInTimeZone } from 'date-fns-tz';
import { isString } from 'lodash';

export const isDateOnlyString = (date: string) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  return isString(date) && date.match(regEx) != null;
};

export const formatDate = (dateString?: string, formatString = defaultDateFormat) => {
  if (dateString) {
    try {
      return format(new Date(dateString), formatString);
    } catch (error) {
      return '-';
    }
  } else {
    return '-';
  }
};

// export const formatDateInTimeZone = (dateString?: string, timeZone: defaultTimeZone, formatString = defaultDateFormat) =>
//   formatDateInTimeZone(new Date(dateString), timeZone) ?? '';

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  if (cleanedNumber.length !== 11) {
    return 'Invalid Phone Number';
  }

  if (cleanedNumber[0] !== '1') {
    return 'Invalid Phone Number';
  }

  const formattedNumber = `+1-${cleanedNumber.slice(1, 4)}-${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7)}`;
  return formattedNumber;
};

export const JSONTryParse = (str: string, callback: (error: any) => any = (err) => ({})) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return callback(error);
  }
};

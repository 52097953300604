import { useTheme } from '@mui/material/styles';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { isArray } from 'lodash';

const textPrimary = '#262626';
const textSecondary = '#8c8c8c';
const border = '#f0f0f0';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 16,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0
    }
  },
  card: {
    border: `1px solid ${border}`,
    borderRadius: '2px',
    padding: '20px',
    width: '48%'
  },
  title: {
    color: textPrimary,
    fontSize: '12px',
    fontWeight: 500
  },
  caption: {
    color: textSecondary,
    fontSize: '10px'
  },
  tableTitle: {
    color: textPrimary,
    fontSize: '10px',
    fontWeight: 500
  },
  tableCell: {
    color: textPrimary,
    fontSize: '10px'
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24
  },

  subRow: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 0,
    paddingBottom: 20
  },
  column: {
    flexDirection: 'column'
  },

  paragraph: {
    color: '#1F2937',
    fontSize: '12px'
  },

  tableHeader: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    paddingTop: '10px',
    paddingBottom: '10px',
    margin: 0,
    paddingLeft: 10
  },
  tableRow: {
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #f0f0f0',
    paddingBottom: 10,
    paddingTop: 10,
    margin: 0,
    paddingLeft: 10
  },
  amountSection: { margin: 0, paddingRight: 25, paddingTop: 16, justifyContent: 'flex-end' },
  amountRow: { margin: 0, width: '40%', justifyContent: 'space-between' },
  pb5: { paddingBottom: 5 },
  flex03: { flex: '0.3 1 0px' },
  flex07: { flex: '0.7 1 0px' },
  flex17: { flex: '1.7 1 0px' },
  flex20: { flex: '2 1 0px' }
});

interface Props {
  list: any | null;
}

const Content = ({ list }: Props) => {
  const theme = useTheme();
  // const subtotal = list?.invoice_detail?.reduce((prev: any, curr: any) => {
  //     if (curr.name.trim().length > 0) return prev + Number(curr.price * Math.floor(curr.qty));
  //     else return prev;
  // }, 0);

  // const taxRate = (Number(list?.tax) * subtotal) / 100;
  // const discountRate = (Number(list?.discount) * subtotal) / 100;
  // const total = subtotal - discountRate;

  let bookingDetailItem = isArray(list?.bookingDetail) ? list?.bookingDetail[0] : list?.bookingDetail;

  return (
    <View style={styles.container}>
      <View style={[styles.row, styles.subRow]}>
        <View style={styles.card}>
          <Text style={[styles.title, { marginBottom: 8 }]}>From:</Text>
          <Text style={[styles.caption, styles.pb5]}>EZPORT INC</Text>
          <Text style={[styles.caption, styles.pb5]}>304 S. Jones Blvd #8005</Text>
          <Text style={[styles.caption, styles.pb5]}>Las Vegas, NV 89107</Text>
          <Text style={[styles.caption, styles.pb5]}>(+1)-212-685-3090</Text>
          <Text style={[styles.caption, styles.pb5]}>billing@ezport.com</Text>
        </View>
        <View style={styles.card}>
          <Text style={[styles.title, { marginBottom: 8 }]}>To:</Text>
          <Text style={[styles.caption, styles.pb5]}>{list?.invoiceDetail?.customerName}</Text>
          <Text style={[styles.caption, styles.pb5]}>
            {bookingDetailItem?.fromLocation.terminalAddress
              ? bookingDetailItem?.fromLocation.terminalAddress
              : bookingDetailItem?.fromLocation.yardAddress
              ? bookingDetailItem?.fromLocation.yardAddress
              : bookingDetailItem?.fromLocation.rampAddress}
          </Text>
          <Text style={[styles.caption, styles.pb5]}>{list?.invoiceDetail?.shippingAddress.phone ?? ''}</Text>
          {/* <Text style={[styles.caption, styles.pb5]}>{list?.customerInfo?.email}</Text> */}
        </View>
      </View>
      <View>
        <View style={[styles.row, styles.tableHeader, { backgroundColor: theme.palette.grey[100] }]}>
          <Text style={[styles.tableTitle, styles.flex03]}>#</Text>
          <Text style={[styles.tableTitle, styles.flex20]}>DESCRIPTION</Text>
          <Text style={[styles.tableTitle, styles.flex07]}>QUANTITY</Text>
          <Text style={[styles.tableTitle, styles.flex07]}>RATE</Text>
          <Text style={[styles.tableTitle, styles.flex07]}>AMOUNT</Text>
        </View>
        {list?.invoiceDetail.lineItems.map((row: any, index: number) => {
          return (
            <View style={[styles.row, styles.tableRow]} key={index.toString()}>
              <Text style={[styles.tableCell, styles.flex03]}>{index + 1}</Text>
              <Text style={[styles.tableCell, styles.flex20]}>
                <Text>{row.description === 'Transportation Rate' ? 'Transportation' : row.description}</Text>
              </Text>
              <Text style={[styles.tableCell, styles.flex07]}>{row.quantity}</Text>
              <Text style={[styles.tableCell, styles.flex07]}>{row.rate}</Text>
              <Text style={[styles.tableCell, styles.flex07]}>{row.item_total}</Text>
            </View>
          );
        })}
        {/* {list?.invoiceDetail.lineItems.map((row: any, index: number) => {
          return (
            <View style={[styles.row, styles.tableRow]} key={row.id}>
              <Text style={[styles.tableCell, styles.flex03]}>{index + 1}</Text>
              <Text style={[styles.tableCell, styles.flex17, { textOverflow: 'ellipsis' }]}>
                {row.description === 'Transportation Rate' ? 'Transportation' : row.description}
                {row.description === 'Transportation Rate' && (
                  <Stack spacing={1}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography color="secondary">Booking:</Typography>
                      <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                        {list?.bookingDetail[0].bookingNo}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography color="secondary">Container Size & Id:</Typography>
                      <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                        {list?.bookingDetail[0].containerSize + ' - ' + list?.bookingDetail[0].containerNo}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography color="secondary">B/L:</Typography>
                      <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                        {list?.bookingDetail[0].billOfLading}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography color="secondary">Reference</Typography>
                      <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                        {list?.bookingDetail[0].referenceNo}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography color="secondary">From:</Typography>
                      <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                        {list?.bookingDetail[index].fromLocation.terminalAddress
                          ? list?.bookingDetail[index].fromLocation.terminalAddress
                          : list?.bookingDetail[index].fromLocation.yardAddress
                          ? list?.bookingDetail[index].fromLocation.yardAddress
                          : list?.bookingDetail[index].fromLocation.rampAddress}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Typography color="secondary">To:</Typography>

                      <Typography variant="subtitle1" sx={{ textAlign: 'right' }}>
                        {list?.bookingDetail[0].fromLocation.terminalAddress
                          ? list?.bookingDetail[0].fromLocation.terminalAddress
                          : list?.bookingDetail[0].fromLocation.yardAddress
                          ? list?.bookingDetail[0].fromLocation.yardAddress
                          : list?.bookingDetail[0].fromLocation.rampAddress}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Text>
              <Text style={[styles.tableCell, styles.flex20]}>{row.quantity}</Text>
              <Text style={[styles.tableCell, styles.flex07]}>{row.rate}</Text>
              <Text style={[styles.tableCell, styles.flex07]}>${row.item_total}</Text>
            </View>
          );
        })} */}
      </View>
      <View style={[styles.row, styles.amountSection]}>
        <View style={[styles.row, styles.amountRow]}>
          <Text style={styles.tableCell}>Grand Total:</Text>
          <Text style={styles.tableCell}>${bookingDetailItem?.total}</Text>
        </View>
      </View>
    </View>
  );
};

export default Content;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { FlagIcon } from 'components/icons/FlagIcon';
import { PlaceIcon } from 'components/icons/PlaceIcon';

export interface FromToAddressCardProps extends React.ComponentProps<typeof Box> {
  from: string;
  to: string;
  hideFrom?: boolean;
}

export const FromToAddressCard: React.FC<FromToAddressCardProps> = ({ from, to, hideFrom = false, ...boxProps }) => {
  return (
    <Box
      borderRadius="4px"
      color="#8C8C8C"
      display="flex"
      sx={{
        boxShadow: '0px 9px 28px 8px #0000000D, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F',
        backgroundColor: '#FFFFFF'
      }}
      {...boxProps}
    >
      {hideFrom ? null : (
        <Box p="12px" borderRight="1px solid #F0F0F0" display="flex" alignItems="center" gap="4px">
          <PlaceIcon />
          <Typography fontSize={12} fontWeight={500} color="#262626">
            {from}
          </Typography>
        </Box>
      )}
      <Box p="12px" display="flex" alignItems="center" gap="4px">
        <FlagIcon />
        <Typography fontSize={12} fontWeight={500} color="#262626">
          {to}
        </Typography>
      </Box>
    </Box>
  );
};

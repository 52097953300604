import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

export interface StepIconProps {
  current: number;
  active: number;
  total: number;
  showProgress?: boolean;
}

export const StepIcon: React.FC<StepIconProps> = ({ current, active, total, showProgress = false }) => {
  const isFirst = current === 0;
  const isLast = current === total - 1;
  const isActive = current === active;
  const isCompleted = current < active;
  const isMiddle = current !== 0 && current !== total - 1;

  const color = React.useMemo(() => {
    if (isActive && isMiddle) {
      return '#FFFFFF';
    }

    if (isCompleted || ((isFirst || isLast) && isActive)) {
      return '#1890FF';
    }

    return '#D9D9D9';
  }, [isActive, isCompleted, isFirst, isLast, isMiddle]);

  return (
    <Box position="relative">
      <Box
        width="24px"
        height="24px"
        borderRadius="50%"
        border="1px solid"
        borderColor={isCompleted || isActive ? ' #1890FF' : '#D9D9D9'}
        color={color}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: isActive && isMiddle ? '#1890FF' : 'transparent'
        }}
      >
        {isCompleted || ((isFirst || isLast) && isActive) ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor">
            <path d="M11.358 1.688h-.936a.426.426 0 0 0-.337.163L4.564 8.846 1.916 5.491a.429.429 0 0 0-.336-.163H.643c-.09 0-.139.103-.084.172l3.668 4.648a.43.43 0 0 0 .674 0l6.541-8.29a.106.106 0 0 0-.084-.17Z" />
          </svg>
        ) : (
          current + 1
        )}
      </Box>
      {isActive && isMiddle && showProgress ? (
        <>
          <Box position="absolute" top={-3} left={-3} width="30px" height="30px" borderRadius="50%" border="2px solid #D9D9D9" />
          <CircularProgress
            size={30}
            value={(current / (total - 1)) * 100}
            thickness={3}
            variant="determinate"
            sx={{ position: 'absolute', top: -3, left: -3 }}
          />
        </>
      ) : null}
    </Box>
  );
};

import React from 'react';
import { LngLat } from 'mapbox-gl';

export const useFetchMapData = (bookingDetailData: any) => {
  const fetchMapData = React.useCallback(async () => {
    const response = await fetch('https://calculator.ezport.com/calculate', {
      method: 'POST',
      body: JSON.stringify({
        fromLocation:
          bookingDetailData?.fromLocation?.locationType === 'address'
            ? bookingDetailData?.fromLocation.address
            : bookingDetailData?.fromLocation?.locationType === 'ocean'
            ? bookingDetailData?.fromLocation?.terminalAddress
            : bookingDetailData?.fromLocation?.rampAddress,
        toLocation:
          bookingDetailData?.toLocation?.locationType === 'address'
            ? bookingDetailData?.toLocation.address
            : bookingDetailData?.toLocation?.locationType === 'ocean'
            ? bookingDetailData?.toLocation?.terminalAddress
            : bookingDetailData?.toLocation?.rampAddress,
        instructions: {}
      })
    });
    const data = await response.json();
    const polylineString: string = data?.polyline;
    const fromLatLng = parseLatLngString(data?.from_location_lat_long);
    const toLatLng = parseLatLngString(data?.to_location_lat_long);

    return { polylineString, fromLatLng, toLatLng };
  }, [
    bookingDetailData?.fromLocation.address,
    bookingDetailData?.fromLocation?.locationType,
    bookingDetailData?.fromLocation?.rampAddress,
    bookingDetailData?.fromLocation?.terminalAddress,
    bookingDetailData?.toLocation.address,
    bookingDetailData?.toLocation?.locationType,
    bookingDetailData?.toLocation?.rampAddress,
    bookingDetailData?.toLocation?.terminalAddress
  ]);

  return fetchMapData;
};

function parseLatLngString(latLngString: string): LngLat {
  const [lat, lng] = latLngString.split(',');
  return new LngLat(parseFloat(lng), parseFloat(lat));
}

'use client';
import React from 'react';

import { Map, MapboxOptions } from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// @ts-expect-error
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

import { Box } from '@mui/material';
import { useFetchMapData } from 'sections/booking-list-detail/MapBackground/useFetchMapData';
import { getRouteBounds } from 'sections/booking-list-detail/MapBackground/getRouteBounds';
import { useShowRoute } from 'sections/booking-list-detail/MapBackground/useShowRoute';
import { useApplyMapLayerStyles } from 'sections/booking-list-detail/MapBackground/useApplyMapLayerStyles';
import { useShowPin } from 'sections/booking-list-detail/MapBackground/useShowPin';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

export interface MapBackgroundProps extends React.ComponentProps<typeof Box> {
  bookingDetailData: any;
  variant: 'pin' | 'path';
  hidePin?: boolean;
}

const MapBackground: React.FC<MapBackgroundProps> = ({ bookingDetailData, variant, hidePin = false, ...boxProps }) => {
  const mapContainer = React.useRef<HTMLDivElement>(null);
  const map = React.useRef<Map | null>(null);

  const fetchMapData = useFetchMapData(bookingDetailData);
  const showRoute = useShowRoute(map);
  const showPin = useShowPin(map);
  const applyMapLayerStyles = useApplyMapLayerStyles(map);

  const initMap = React.useCallback(async () => {
    if (map.current) return; // initialize map only once

    const { polylineString, fromLatLng, toLatLng } = await fetchMapData();

    const bounds = variant === 'path' ? getRouteBounds(polylineString) : undefined;

    const mapboxOptions: MapboxOptions = {
      container: mapContainer.current || '',
      style: 'mapbox://styles/mapbox/streets-v12',
      attributionControl: false,
      interactive: true,
      center: fromLatLng,
      zoom: 12,
      bounds,
      fitBoundsOptions: {
        padding: { top: 80, bottom: 20, left: 20, right: 20 }
      }
    };

    map.current = new mapboxgl.Map(mapboxOptions);

    map.current?.on('load', function () {
      if (variant === 'pin' && !hidePin) {
        showPin(fromLatLng);
      }

      if (variant === 'path') {
        showRoute(polylineString, fromLatLng, toLatLng);
      }

      applyMapLayerStyles();
    });
  }, [applyMapLayerStyles, fetchMapData, hidePin, showPin, showRoute, variant]);

  React.useEffect(() => {
    initMap();
  }, [initMap]);

  return <Box ref={mapContainer} position="absolute" width="100%" minHeight="36rem" height="100%" overflow="hidden" {...boxProps} />;
};

export default MapBackground;

import endpoints from 'constants/endpoints';
import axiosServices from 'utils/axios';

export const getEmployeesServ = async (): Promise<any> => {
  return (await axiosServices.get(endpoints.getEmployees)).data;
};

export const inviteResendEmployeeServ = async (email: string): Promise<any> => {
  return (await axiosServices.post(endpoints.resendInvite, { email })).data;
};

export const getNotificationSettingsServ = async (): Promise<any> => {
  return (await axiosServices.post(endpoints.resendInvite)).data;
};

export const updateEmployeeServ = async (id: any, data: any): Promise<any> => {
  try {
    const response = (await axiosServices.put(endpoints.updateEmployee + id, data)).data;
    return response;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateEmployeeRoleServ = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.put(endpoints.updateEmployeeRole, data)).data;
    return response;
  } catch (err: any) {
    console.log(err);
  }
};

export const postEmployeeServ = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.postEmployee, data)).data;
    return response;
  } catch (err: any) {
    console.log(err);
  }
};

export const deleteEmployeeServ = async (id: any) => {
  try {
    const response = (await axiosServices.delete(endpoints.deleteEmployee + id)).data;
    return response;
  } catch (err: any) {
    console.log(err);
  }
};

export const getRolesServ = async (): Promise<any> => {
  return (await axiosServices.get(endpoints.getRoles)).data;
};

/* eslint-disable @typescript-eslint/no-unused-expressions */
import endpoints from 'constants/endpoints';
import axiosServices from 'utils/axios';
import { errorMessageSnackbar } from 'utils/snackbar-message';

export const addDocumentServ = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.addDocument, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import { APP_DEFAULT_PATH } from 'config';

// types
import { GuardProps } from 'types/auth';
import { useSelector } from 'react-redux';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const loginReducer = useSelector((state: any) => state.loginReducer);
  const user = localStorage.getItem('userInfo');
  const isLoggedIn: any = user ? true : false || loginReducer.isLoggedIn;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH, {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default GuestGuard;

// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { DeleteFilled } from '@ant-design/icons';
import { useState } from 'react';

// types
interface Props {
  title?: string;
  open: boolean;
  handleClose: (status: boolean) => Promise<void>;
  deleteData?: () => void;
}

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function DeleteModal({ title, open, handleClose, deleteData }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color="error" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            <DeleteFilled />
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              Are you sure you want to cancel?
            </Typography>
            <Typography align="center">
              <Typography variant="subtitle1" component="span">
                {title}
              </Typography>
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={() => handleClose(false)} color="secondary" variant="outlined">
              No
            </Button>
            <Button
              disabled={loading}
              fullWidth
              color="error"
              variant="contained"
              autoFocus
              onClick={async () => {
                setLoading(true);
                await handleClose(true);
                setLoading(false);
              }}
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

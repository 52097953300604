import { Box } from '@mui/material';
import React from 'react';
import { VerticalStepper } from 'sections/booking-list-detail/VerticalStepper';
import { selectRoadwayOptions, selectRoadwayStatus } from 'sections/booking-list-detail/selectors';

export interface RoadwayStatusStepperProps extends React.ComponentProps<typeof Box> {
  bookingDetail: any;
}

export const RoadwayStatusStepper: React.FC<RoadwayStatusStepperProps> = ({ bookingDetail, sx, ...boxProps }) => {
  const steps = selectRoadwayOptions(bookingDetail);
  const step = selectRoadwayStatus(bookingDetail);

  return <VerticalStepper steps={steps} value={step} sx={sx} {...boxProps} />;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import endpointsConstant from 'constants/endpoints';
import axiosServices from 'utils/axios';

export interface IData {
  deletedAt: any;
  sendAt: any;
  id: number;
  name: string;
  company_name: string;
  contact_name: string;
  mail: string;
  phone: string;
  city: string;
  state: string;
  zip_code: number;
  address: string;
  location: any;
}

export interface BoosterListState {
  data: IData[];
  error: boolean;
  message: any;
  loading: boolean;
}

const initialState: BoosterListState = {
  data: [],
  error: false,
  message: '',
  loading: true
};

export const getAllAddress = createAsyncThunk('/adressBook', async (data, thunkAPI) => {
  try {
    const response: { data: any; error: boolean } = await axiosServices.get(endpointsConstant.getAddressBook);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const handleLoading = createAction('boosters/handleloading');
export const setLoading = createAction('boosters/setloading');

export const addressBook = createSlice({
  name: 'addressBook',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAddress.fulfilled, (state, action) => {
        state.data = action.payload.data ?? [];
        state.error = action.payload.error;
        state.loading = false;
      })
      .addCase(getAllAddress.rejected, (state, action) => {
        state.error = true;
        state.message = action.payload;
        state.loading = false;
      })
      .addCase(setLoading, (state) => {
        state.loading = true;
      })
      .addCase(handleLoading, (state) => {
        state.loading = false;
      });
  }
});

export default addressBook.reducer;

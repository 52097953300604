import { Box, Typography } from '@mui/material';
import React from 'react';
import { StepIcon } from 'sections/booking-list-detail/BookingStatusStepper/StepIcon';

export type BookingStausStep = {
  key: string;
  title: string;
};

export interface BookingStatusStepperProps {
  steps: BookingStausStep[];
  activeStep?: string;
}

export const BookingStatusStepper: React.FC<BookingStatusStepperProps> = ({ steps, activeStep }) => {
  const activeIndex = React.useMemo(() => steps.findIndex((step) => step.key === activeStep) ?? -1, [activeStep, steps]);

  if (!activeStep) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" p="24px" gap="16px">
      {steps.map((step, index) => (
        <Box key={step.title} flexGrow={1} display="flex" alignItems="center" gap="8px">
          <StepIcon current={index} active={activeIndex} total={steps.length} showProgress />

          <Typography fontSize={16} fontWeight={400} color={index <= activeIndex ? '#262626' : '#8C8C8C'}>
            {step.title}
          </Typography>

          {index !== steps.length - 1 ? (
            <Box flexGrow={1} borderBottom="1px solid" borderColor={index < activeIndex ? '#1890FF' : '#F0F0F0'} />
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

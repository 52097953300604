import FinishFlagIcon from 'components/CustomComponents/BookingDetail/StepperIcons/finish-flag';
import ShipIcon from 'components/CustomComponents/BookingDetail/StepperIcons/ship';
import TerminalIcon from 'components/CustomComponents/BookingDetail/StepperIcons/terminal';
import TruckIcon from 'components/CustomComponents/BookingDetail/StepperIcons/truck';
import WarehouseIcon from 'components/CustomComponents/BookingDetail/StepperIcons/warehouse';

export const defaultDateFormat = 'MM/dd/yyyy';
export const defaultDateTimeFormat = 'KK:mm:ss aaa';
export const defaultTimeZone = 'America/Los_Angeles';
export const defaultDateFormatMask = '__/__/____';

export const lifecycleSteps = [
  {
    key: 'booked',
    title: 'Booked',
    description: 'Booking created by customer',
    icon: ({ ...props }) => <WarehouseIcon {...props} />
  },
  {
    key: 'ocean_voyage',
    title: 'Ocean Voyage',
    description: 'Booking created by customer',
    icon: ({ ...props }) => <ShipIcon {...props} />
  },
  {
    key: 'terminal',
    title: 'Terminal',
    description: 'Booking created by customer',
    icon: ({ ...props }) => <TerminalIcon {...props} />
  },
  {
    key: 'roadway',
    title: 'Road Way',
    description: 'Booking created by customer',
    icon: ({ ...props }) => <TruckIcon {...props} />
  },
  {
    key: 'completed',
    title: 'Completed',
    description: 'Booking created by customer',
    icon: ({ ...props }) => <FinishFlagIcon {...props} />
  }
];

export const DEFAULT_OCEAN_VOYAGE_DURATION_DAYS = 20; // Assume that ocean voyage always takes 20 days

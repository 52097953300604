/* eslint-disable @typescript-eslint/no-unused-expressions */
import endpoints from 'constants/endpoints';
import axiosServices from 'utils/axios';
import { errorMessageSnackbar } from 'utils/snackbar-message';

export const getInvoiceBalanceServ = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getInvoiceBalance)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getBillingInfoServ = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getBillingInfo)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getUserWalletServ = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getUserWallet)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getInvoiceListServ = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.getInvoiceList, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const updateBillingInfoServ = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.updateBillingInfo, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getCountries = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getCountries)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getInvoiceDetail = async (id: any): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getInvoiceDetail + '/' + id)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const payInvoiceServ = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.payInvoice, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const payInvoiceWithCreditServ = async (invoiceId: number): Promise<any> => {
  try {
    const response = (await axiosServices.post(`${endpoints.payInvoiceWithCredit}/${invoiceId}`)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const payMultipleInvoiceServ = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.payMultipleInvoice, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const downloadInvoice = async (id: any): Promise<any> => {
  try {
    const response = (
      await axiosServices.get(endpoints.downloadInvoice + '/' + id + '/download', {
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      })
    ).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

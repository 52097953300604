import React from 'react';
import MainCard from 'components/MainCard';
import { InvoiceView } from 'pages/invoice/InvoiceView';
import { useParams } from 'react-router';
import { getInvoiceDetail } from 'services/finance.service';
import { getBookingListDetailService } from 'services/bookingList.service';

const InvoicePage: React.FC = () => {
  const invoiceIdString = useParams<string>().invoiceId;
  const invoiceId = Number(invoiceIdString);

  const [invoiceDetail, setInvoiceDetail] = React.useState<any>();
  const [bookingDetail, setBookingDetail] = React.useState<any>();

  const fetchData = React.useCallback(async (invoiceId: number) => {
    const {
      data: { invoiceDetail: invoiceData }
    } = await getInvoiceDetail(invoiceId);
    setInvoiceDetail(invoiceData);

    const bookingId = invoiceData?.bookingId;

    if (!bookingId || Number.isNaN(bookingId)) {
      return;
    }

    const { data: bookingData } = await getBookingListDetailService({
      trackingNo: `${bookingId}`
    });

    setBookingDetail(bookingData);
  }, []);

  React.useEffect(() => {
    if (Number.isNaN(invoiceId)) {
      return;
    }

    fetchData(invoiceId);
  }, [fetchData, invoiceId]);

  return (
    <MainCard title="Invoice">
      {Number.isNaN(invoiceId) || !invoiceDetail || !bookingDetail ? null : (
        <InvoiceView invoiceDetail={invoiceDetail} bookingDetail={bookingDetail} />
      )}
    </MainCard>
  );
};

export default InvoicePage;

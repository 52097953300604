import React from 'react';
import { Map, Marker, LngLat } from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// @ts-expect-error
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

export const ROUTE_LAYER = 'route';

export const useShowPin = (map: React.MutableRefObject<Map | null>) => {
  const showPin = React.useCallback(
    (latLng: LngLat) => {
      if (!map.current) {
        return;
      }

      const pinElement = createPinElement();
      const marker = new mapboxgl.Marker(pinElement, { anchor: 'bottom' }) as Marker;
      marker.setLngLat(latLng);
      marker.addTo(map.current);
    },
    [map]
  );

  return showPin;
};

function createPinElement() {
  var xmlns = 'http://www.w3.org/2000/svg';
  var boxWidth = '42';
  var boxHeight = '50';

  var svgElem = document.createElementNS(xmlns, 'svg');
  svgElem.setAttributeNS(null, 'viewBox', '0 0 ' + boxWidth + ' ' + boxHeight);
  svgElem.setAttributeNS(null, 'width', boxWidth);
  svgElem.setAttributeNS(null, 'height', boxHeight);

  var g = document.createElementNS(xmlns, 'g');
  svgElem.appendChild(g);

  var path1 = document.createElementNS(xmlns, 'path');
  path1.setAttributeNS(null, 'fill', '#FFFFFF');
  path1.setAttributeNS(
    null,
    'd',
    'M21.75 9.5h-1.5c-5.385 0-9.75 4.365-9.75 9.75S14.865 29 20.25 29h1.5c5.385 0 9.75-4.365 9.75-9.75S27.135 9.5 21.75 9.5Z'
  );

  var path2 = document.createElementNS(xmlns, 'path');
  path2.setAttributeNS(null, 'fill', '#1890FF');
  path2.setAttributeNS(
    null,
    'd',
    'M21 2A16.519 16.519 0 0 0 4.5 18.5c0 14.119 15 24.782 15.64 25.228a1.5 1.5 0 0 0 1.72 0c.64-.446 15.64-11.11 15.64-25.228A16.519 16.519 0 0 0 21 2Zm0 10.5a6 6 0 1 1 0 12 6 6 0 0 1 0-12Z'
  );

  g.appendChild(path1);
  g.appendChild(path2);

  const el = document.createElement('div');
  el.appendChild(svgElem);

  return el;
}

import React from 'react';
import { Box, Typography } from '@mui/material';

export interface PickupDateFieldProps {
  icon: JSX.Element;
  label: string;
}

export const PickupDateField: React.FC<PickupDateFieldProps> = ({ icon, label }) => {
  return (
    <Box
      p="9px 16px"
      display="flex"
      gap="8px"
      width="100%"
      color="#D9D9D9"
      border="1px solid #D9D9D9"
      borderRadius="4px"
      boxShadow="0px 2px 0px 0px #00000004"
      alignItems="center"
    >
      {icon}
      <Typography fontSize={14} lineHeight="1.6" fontWeight={400} color="#192129">
        {label}
      </Typography>
    </Box>
  );
};

// project import
import { useState, useEffect, SyntheticEvent } from 'react';
import { Box, Divider, Grid, Stack, Tab, Tabs, Button, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { TabsProps } from 'types/tabs';
import { Link } from 'react-router-dom';
import ActivityComp from './Activity';
import DocumentsComp from './Documents';
import axiosServices from 'utils/axios';
import { addDocumentServ } from 'services/document.service';
import { getActivityListService } from 'services/bookingList.service';
import { errorMessageSnackbar } from 'utils/snackbar-message';

const MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024; // 10 Mb
const SPECIAL_CHARACTER_REGEX = /[^a-zA-Z0-9.]/g;

function TabPanel({ children, value, index, ...other }: TabsProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`users-tabpanel-${index}`} aria-labelledby={`users-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`
  };
}

interface Props {
  bookingDetailData: any;
}

const BookingActivityPanel = ({ bookingDetailData }: Props) => {
  const [value, setValue] = useState(0);
  const [documentsData, setDocumentsData] = useState<any>(bookingDetailData?.documents ?? []);
  const [loading, setLoading] = useState<boolean>(false);
  const [activities, setActivities] = useState<any>([]);
  const [isLoadingGetActivities, setIsLoadingGetActivities] = useState<boolean>(false);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getActivities = async () => {
    setIsLoadingGetActivities(true);
    const activityList = await getActivityListService(bookingDetailData?.id);
    setActivities(activityList);
    setIsLoadingGetActivities(false);
  };

  useEffect(() => {
    getActivities();
    setDocumentsData(bookingDetailData?.documents ?? []);
  }, []);

  const handleAddDocuments = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;
    input.click();

    input.onchange = async () => {
      const files = input.files;
      if (files && files.length > 0) {
        const filename = files[0].name.replace(SPECIAL_CHARACTER_REGEX, '_');

        if (files[0].size > MAX_FILE_SIZE_BYTES) {
          errorMessageSnackbar('File size must not exceed 10 Mb');
          return;
        }

        try {
          setLoading(true);
          const formData = new FormData();
          formData.append('file', files[0], filename);
          formData.append('directory', 'booking_documents');
          formData.append('metadata', '');

          let response: any;

          try {
            response = await axiosServices.post('https://file-upload-api.ezport.com/upload/file/single', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          } catch (err: any) {
            if (err?.errors) {
              errorMessageSnackbar(err?.errors);
              return;
            }
          }

          const data = {
            bookingId: bookingDetailData?.id,
            owner: 'Owner',
            type: 'doc',
            title: filename,
            description: `Document for booking: ${bookingDetailData?.bookingNo}`,
            mediaLink: response?.data?.data?.mediaLink,
            blobFileId: response?.data?.data?.blobFileId
          };

          const documentValues = await addDocumentServ(data);
          setDocumentsData((prevDocument: any) => [
            ...prevDocument,
            {
              description: documentValues?.data?.newDocument?.description,
              id: documentValues?.data?.newDocument?.id,
              owner: documentValues?.data?.newDocument?.owner,
              title: documentValues?.data?.newDocument?.title,
              type: documentValues?.data?.newDocument?.type,
              url: documentValues?.data?.newDocument?.url
            }
          ]);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      }
    };
  };

  return (
    <Grid item xs={12} md={12} xl={12} sx={{ height: '38rem', position: 'relative' }}>
      <MainCard sx={{ height: '100%' }}>
        <Stack spacing={3}>
          <Stack>
            <Tabs
              value={value}
              indicatorColor="primary"
              onChange={handleChange}
              aria-label="product description tabs example"
              variant="scrollable"
            >
              <Tab component={Link} to="#" label="Activity" {...a11yProps(0)} />
              <Tab component={Link} to="#" label="Documents" {...a11yProps(1)} />
              {/* <Tab component={Link} to="#" label="Expenses" {...a11yProps(2)} /> */}
            </Tabs>
            <Divider />
          </Stack>
          <TabPanel value={value} index={0}>
            <Box sx={{ overflow: 'auto', maxHeight: 450 }}>
              <ActivityComp activities={activities} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ overflow: 'auto', maxHeight: 450 }}>
              <DocumentsComp
                bookingDetailData={bookingDetailData}
                documentsData={documentsData}
                setDocumentsData={setDocumentsData}
                loading={loading}
                setLoading={setLoading}
              />
            </Box>
          </TabPanel>
        </Stack>
      </MainCard>
      <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: '0.5rem' }}>
        <Divider />
        {value === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0.5rem' }}>
            <Button variant="outlined" color="secondary" sx={{ width: '9rem' }} onClick={getActivities} disabled={isLoadingGetActivities}>
              Refresh
            </Button>
          </div>
        )}
        {value === 1 && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0.5rem' }}>
            <Button variant="contained" color="primary" sx={{ width: '9rem' }} onClick={handleAddDocuments}>
              Add New File
            </Button>
          </div>
        )}
        {value === 2 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
            <Typography>Total</Typography>
            <strong>
              $
              {bookingDetailData?.expenses?.reduce((total: number, item: any) => {
                return total + item?.value;
              }, 0) ?? 0}
            </strong>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default BookingActivityPanel;

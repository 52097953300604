/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from 'react';
import { DeleteOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Box, Typography, Link } from '@mui/material';
import { ReactComponent as PdfIcon } from 'assets/images/icons/pdf-icon.svg';
import { ReactComponent as ExcelIcon } from 'assets/images/icons/excel-icon.svg';
import { ReactComponent as JpgIcon } from 'assets/images/icons/jpg-icon.svg';
import { ReactComponent as PptIcon } from 'assets/images/icons/ppt-icon.svg';
import DeleteModal from 'components/Alert/DeleteModal';
import { deleteDocumentService } from 'services/bookingList.service';
import SkeletonList from 'components/SkeletonList/SkeletonList';

interface Props {
  bookingDetailData: any;
  documentsData: any;
  setDocumentsData: any;
  loading: boolean;
  setLoading: any;
}

const DocumentsComp = ({ bookingDetailData, documentsData, setDocumentsData, loading, setLoading }: Props) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<any>(null);

  const handleCloseDeleteModal = async (isDelete?: boolean) => {
    setLoading(true);

    try {
      if (isDelete) {
        const responseDocument = await deleteDocumentService(`${deleteData.id}/${bookingDetailData.id}`);
        if (!responseDocument?.error && responseDocument?.data !== undefined) {
          const newDocumensData = documentsData.filter((item: any) => item.id !== deleteData.id);
          setDocumentsData(newDocumensData);
        }
      }
      setOpenDeleteModal(!openDeleteModal);
      setDeleteData(null);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDocument = (data: any) => {
    setDeleteData(data);
    setOpenDeleteModal(!openDeleteModal);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap="20px">
        {loading ? (
          <SkeletonList count={7} />
        ) : documentsData.length > 0 ? (
          documentsData.map((item: any) => (
            <Box width="100%" display="flex" alignItems="center" p="10px" gap="10px" sx={{ background: '#FAFAFA' }} key={item.id}>
              <Box
                p="4px"
                sx={{
                  background:
                    item.type === 'pdf' ? '#FFF1F0' : item.type === 'excel' ? '#F6FFED' : item.type === 'jpg' ? '#E6F7FF' : '#FFF7E6'
                }}
              >
                {item.type === 'pdf' ? (
                  <PdfIcon style={{ fontSize: '1rem' }} />
                ) : item.type === 'excel' ? (
                  <ExcelIcon style={{ fontSize: '1rem', color: 'inherit' }} />
                ) : item.type === 'jpg' ? (
                  <JpgIcon style={{ fontSize: '1rem', color: 'inherit' }} />
                ) : (
                  <PptIcon style={{ fontSize: '1rem', color: 'inherit' }} />
                )}
              </Box>

              <Typography flexGrow={1} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                {item.title}
              </Typography>

              <Box display="flex" alignItems="center" gap="10px">
                <Link href={item.url} target="_blank" rel="noreferrer">
                  {/* @ts-expect-error */}
                  <EyeOutlined />
                </Link>
                <Link href={item.url} target="_blank" rel="noreferrer">
                  {/* @ts-expect-error */}
                  <DownloadOutlined />
                </Link>
                {/* @ts-expect-error */}
                <DeleteOutlined style={{ color: '#F96F76', cursor: 'pointer' }} onClick={() => handleDeleteDocument(item)} />
              </Box>
            </Box>
          ))
        ) : (
          <Typography>There are no documents for this booking.</Typography>
        )}
      </Box>
      <DeleteModal title={deleteData?.title} open={openDeleteModal} handleClose={handleCloseDeleteModal} />
    </>
  );
};

export default DocumentsComp;

import React from 'react';
import MainCard from 'components/MainCard';
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useCallback, useEffect, useState } from 'react';
import { EditFilled } from '@ant-design/icons';
import { InputFieldLabel } from 'components/InputFieldLabel';

interface Props {
  bookingDetailData: any;
  onUpdate: (data: any) => void;
  isLoading: boolean;
}

const formDataDummy = {
  containerSize: [
    { value: "20'DC", label: "20'DC" },
    { value: "40'DC", label: "40'DC" },
    { value: "40'HC", label: "40'HC" },
    { value: '45', label: '45' },
    { value: "45'HC", label: "45'HC" }
  ],

  transportationType: [
    { value: 'Drop & Pick', label: 'Drop & Pick' },
    { value: 'Live Load', label: 'Live Load' }
  ]
};

function Instructions({ bookingDetailData, onUpdate, isLoading }: Props) {
  const [selectedContainerSize, setSelectedContainerSize] = useState<{ label: string; value: string }>(formDataDummy.containerSize[0]);
  const [selectedTransType, setSelectedTransType] = useState<{ label: string; value: string }>(formDataDummy.transportationType[0]);
  const [contentDesc, setContentDesc] = useState<string>(bookingDetailData.dopag);
  const [selectedDate, setSelectedDate] = useState<Date>(bookingDetailData.prepullDate);
  const [isPrePull, setPrePull] = useState<boolean>(bookingDetailData.storageType === 'Pre Pull');
  const [selectedCheckbox, setSelectedCheckbox] = useState<any>({
    isReefer: bookingDetailData.isReefer,
    isOverweight: bookingDetailData.isOverweight,
    isHazardous: bookingDetailData.isHazardous
  });

  const findMatchingContainer = useCallback(() => {
    const selectedContainerSize = bookingDetailData.containerSize;
    const matchingContainer = formDataDummy.containerSize.find((container) => container.value === selectedContainerSize);

    if (matchingContainer) {
      setSelectedContainerSize(matchingContainer);
    } else {
      setSelectedContainerSize(formDataDummy.containerSize[0]);
    }
  }, [bookingDetailData.containerSize]);

  const findMatchedTransType = useCallback(() => {
    const selectedContainerSize = bookingDetailData.transportationType === 'drop_and_pick' ? 'Drop & Pick' : 'Live Load';
    const matchingContainer = formDataDummy.transportationType.find((container) => container.value === selectedContainerSize);

    if (matchingContainer) {
      setSelectedTransType(matchingContainer);
    } else {
      setSelectedTransType(formDataDummy.transportationType[0]);
    }
  }, [bookingDetailData.transportationType]);

  useEffect(() => {
    findMatchingContainer();
    findMatchedTransType();
  }, [findMatchingContainer, findMatchedTransType]);

  const [isEdited, setIsEdited] = React.useState(false);

  const resetStates = React.useCallback(() => {
    setSelectedContainerSize({ label: bookingDetailData.containerSize, value: bookingDetailData.containerSize });
    setSelectedTransType(
      bookingDetailData.transportationType === 'drop_and_pick'
        ? { label: 'Drop & Pick', value: 'Drop & Pick' }
        : { label: 'Live Load', value: 'Live Load' }
    );
    setContentDesc(bookingDetailData.dopag);
    setSelectedDate(bookingDetailData.prepullDate);
    setPrePull(bookingDetailData.storageType === 'Pre Pull');
    setSelectedCheckbox({
      isReefer: bookingDetailData.isReefer,
      isOverweight: bookingDetailData.isOverweight,
      isHazardous: bookingDetailData.isHazardous
    });
  }, [
    bookingDetailData.containerSize,
    bookingDetailData.dopag,
    bookingDetailData.isHazardous,
    bookingDetailData.isOverweight,
    bookingDetailData.isReefer,
    bookingDetailData.prepullDate,
    bookingDetailData.storageType,
    bookingDetailData.transportationType
  ]);

  const handleCancel = React.useCallback(() => {
    setIsEdited(false);
    resetStates();
  }, [resetStates]);

  const handleSave = React.useCallback(() => {
    onUpdate({
      containerSize: selectedContainerSize.value,
      transportationType: selectedTransType.value === 'Drop & Pick' ? 'drop_and_pick' : 'live_load',
      isPrePull: isPrePull,
      date: selectedDate,
      isReefer: selectedCheckbox.isReefer,
      isOverweight: selectedCheckbox.isOverweight,
      isHazardous: selectedCheckbox.isHazardous,
      dopag: contentDesc
    });
  }, [
    contentDesc,
    isPrePull,
    onUpdate,
    selectedCheckbox.isHazardous,
    selectedCheckbox.isOverweight,
    selectedCheckbox.isReefer,
    selectedContainerSize.value,
    selectedDate,
    selectedTransType.value
  ]);

  const handleEdit = React.useCallback(() => {
    setIsEdited(true);
  }, []);

  return (
    <MainCard
      title={`Instructions`}
      secondary={
        isEdited ? (
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <Button variant="outlined" color="secondary" onClick={handleCancel} size="small">
              <Typography variant="caption" color="#262626">
                Cancel
              </Typography>
            </Button>
            <Button variant="contained" color={'primary'} disabled={isLoading} onClick={handleSave} size="small">
              <Typography variant="caption" color="#ffffff">
                Save
              </Typography>
            </Button>
          </div>
        ) : (
          <Button variant="outlined" color="secondary" onClick={handleEdit} size="small" startIcon={<EditFilled />}>
            <Typography variant="caption" color="#262626">
              Edit
            </Typography>
          </Button>
        )
      }
    >
      <Grid container spacing={2.5}>
        <Grid item md={6} container direction={'row'} justifyContent="space-between" alignItems="center">
          <InputFieldLabel disabled={!isEdited}>Container Size</InputFieldLabel>
          <Stack spacing={1} width={'50%'}>
            <Select
              fullWidth
              value={selectedContainerSize.value}
              onChange={(e) => setSelectedContainerSize({ label: e.target.value, value: e.target.value })}
              style={{ color: 'black' }}
              disabled={!isEdited}
            >
              {formDataDummy.containerSize.map((item: any) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={6} container direction={'row'} justifyContent="space-between" alignItems="center">
          <RadioGroup
            style={{ width: '100%' }}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <FormControlLabel
                checked={selectedCheckbox.isReefer}
                style={{ width: '100%' }}
                value="Reefer"
                control={<Checkbox value={selectedCheckbox.isReefer} />}
                label="Reefer"
                onChange={(e) => setSelectedCheckbox({ ...selectedCheckbox, isReefer: !selectedCheckbox.isReefer })}
                disabled={!isEdited}
              />
              <FormControlLabel
                checked={selectedCheckbox.isOverweight}
                style={{ width: '100%' }}
                value="Overweight"
                control={<Checkbox value={selectedCheckbox.isOverweight} />}
                label="Overweight"
                onChange={(e) => setSelectedCheckbox({ ...selectedCheckbox, isOverweight: !selectedCheckbox.isOverweight })}
                disabled={!isEdited}
              />
              <FormControlLabel
                checked={selectedCheckbox.isHazardous}
                style={{ width: '100%' }}
                value="Hazardous"
                onChange={(e) => setSelectedCheckbox({ ...selectedCheckbox, isHazardous: !selectedCheckbox.isHazardous })}
                control={<Checkbox value={selectedCheckbox.isHazardous} />}
                label="Hazardous"
                disabled={!isEdited}
              />
            </div>
          </RadioGroup>
        </Grid>
        <Grid item md={6} container direction={'row'} justifyContent="space-between" alignItems="center">
          <InputFieldLabel disabled={!isEdited}>Transportation Type</InputFieldLabel>
          <Stack spacing={1} width={'50%'}>
            <Select
              fullWidth
              value={selectedTransType.value}
              style={{ color: 'black' }}
              onChange={(e) => setSelectedTransType({ label: e.target.value, value: e.target.value })}
              disabled={!isEdited}
            >
              {formDataDummy.transportationType.map((item: any) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={6} container direction={'row'} justifyContent="space-between" alignItems="center">
          <InputFieldLabel disabled={!isEdited}>Content Description</InputFieldLabel>
          <TextField
            id="content_description"
            placeholder="20’ Hibiscus Flower"
            value={contentDesc}
            onChange={(e) => setContentDesc(e.target.value)}
            sx={{ width: '50%' }}
            disabled={!isEdited}
          />
        </Grid>

        {isPrePull && (
          <Grid item xs={6} container direction={'row'} justifyContent="space-between" alignItems="center">
            <InputFieldLabel disabled={!isEdited}>Pre-Pull Delivery Date</InputFieldLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div style={{ width: '50%' }}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={selectedDate}
                  onChange={(value) => setSelectedDate(value ? value : new Date())}
                  renderInput={(params) => <TextField {...params} placeholder="Date" />}
                  disablePast={true}
                  disabled={!isPrePull || !isEdited}
                />
              </div>
            </LocalizationProvider>
          </Grid>
        )}
      </Grid>
    </MainCard>
  );
}

export default Instructions;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEmployeesServ, getRolesServ } from 'services/users.service';
import { RootState } from '..';
import { Employee } from 'types/user';

const RoleMap = {
  user_basic: 'User',
  user_admin: 'Admin',
  super_admin: 'Super Admin',
  driver: 'Driver'
} as const;

export interface EmployeesState {
  members: Array<Employee>;
  invitationPendingEmployees: Array<Employee>;
  roles: any[];
  status: 'loading' | 'completed' | 'failed';
}

const initialState: EmployeesState = {
  members: [],
  invitationPendingEmployees: [],
  roles: [],
  status: 'loading'
};

export const getEmployees = createAsyncThunk('user/getEmployees', async (data, thunkAPI) => {
  try {
    const employees = (await getEmployeesServ()).data?.users;
    const roles = (await getRolesServ()).data?.roleList;

    return { employees, roles };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.status = 'completed';
        state.members = action.payload.employees.filter((user: Employee) => user.isInvitationAccepted);
        state.invitationPendingEmployees = action.payload.employees.filter((user: Employee) => !user.isInvitationAccepted);
        state.roles = action.payload.roles.map((role: any) => ({
          ...role,
          name: RoleMap[role.name as keyof typeof RoleMap]
        }));
      })
      .addCase(getEmployees.rejected, (state) => {
        state.status = 'failed';
      });
  }
});

export const selectEmployees = (state: RootState) => ({
  membersData: state.employees.members,
  invitationPendingEmployeesData: state.employees.invitationPendingEmployees,
  roles: state.employees.roles,
  status: state.employees.status
});

export default employeesSlice.reducer;

export const ClockCircleIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M7.19043 0.874512C3.80801 0.874512 1.06543 3.61709 1.06543 6.99951C1.06543 10.3819 3.80801 13.1245 7.19043 13.1245C10.5729 13.1245 13.3154 10.3819 13.3154 6.99951C13.3154 3.61709 10.5729 0.874512 7.19043 0.874512ZM7.19043 12.0854C4.38223 12.0854 2.10449 9.80772 2.10449 6.99951C2.10449 4.19131 4.38223 1.91357 7.19043 1.91357C9.99863 1.91357 12.2764 4.19131 12.2764 6.99951C12.2764 9.80772 9.99863 12.0854 7.19043 12.0854Z" />
        <path d="M9.57901 8.72909L7.6294 7.31952V3.93573C7.6294 3.87557 7.58018 3.82636 7.52003 3.82636H6.86241C6.80226 3.82636 6.75304 3.87557 6.75304 3.93573V7.70096C6.75304 7.73651 6.76944 7.76932 6.79815 7.78983L9.05948 9.43866C9.1087 9.47421 9.17706 9.46327 9.21261 9.41542L9.60362 8.88221C9.63917 8.83163 9.62823 8.76327 9.57901 8.72909Z" />
      </g>
    </svg>
  );
};

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from 'assets/images/logo.png';
import { isArray } from 'lodash';

const textPrimary = '#262626';
const textSecondary = '#8c8c8c';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row'
  },
  detailColumn: {
    marginBottom: '12px',
    flexDirection: 'column',
    flexGrow: 2
  },
  chipTitle: {
    fontSize: '8px',
    padding: 4
  },
  chip: {
    alignItems: 'center',
    borderRadius: '4px',
    marginLeft: 52,
    marginRight: 4,
    marginBottom: 8
  },
  leftColumn: {
    flexDirection: 'column',
    width: 28,
    marginRight: 10,
    paddingLeft: 4,
    marginTop: 4
  },
  image: {
    width: 28,
    height: 28
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch'
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  title: {
    color: textPrimary,
    fontSize: '10px'
  },
  titleHeader: {
    color: textPrimary,
    fontSize: '18px'
  },
  caption: {
    color: textSecondary,
    fontSize: '10px'
  }
});

interface Props {
  list: any;
}

const Header = ({ list }: Props) => {
  let bookingDetailItem = isArray(list?.bookingDetail) ? list?.bookingDetail[0] : list?.bookingDetail;
  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <View style={styles.leftColumn}>
          <Image src={Logo} style={styles.image} />
        </View>
        <View>
          <View style={[styles.row, { marginTop: 4, marginLeft: 8 }]}>
            <Text style={[styles.titleHeader, { marginTop: 4 }]}>Ezport</Text>
          </View>
        </View>
      </View>
      <View>
        <View style={[styles.row, { marginTop: 8 }]}>
          <Text style={styles.title}>Booking No:</Text>
          <Text style={styles.caption}>{bookingDetailItem?.id}</Text>
        </View>
      </View>
    </View>
  );
};

export default Header;

import { useRef, useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { defaultDateFormat, defaultDateTimeFormat } from 'constants/constants';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

// assets
import { BellOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
// loadingAction
// REDUCER
import { getNotificationList, loadingAction } from 'store/reducers/notificationList';
import { dispatch, useSelector } from 'store';
import { markedAllReadNotificationsService, markedMultipleReadNotificationsService } from 'services/notification.service';

// helper
import { formatDate } from 'helpers/Utils';
import { Stack } from '@mui/system';

interface INotifications {
  id: number;
  description: string;
  read: boolean;
  type: string;
  user_id: number;
  created_at: string;
  isChecked: boolean;
}

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const anchorRef = useRef<any>(null);
  const [read, setRead] = useState<number>(0);
  const [open, setOpen] = useState(false);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  useEffect(() => {
    dispatch(
      getNotificationList({
        page: 1,
        limit: 10,
        sortOrder: 'DESC',
        sortBy: 'created_at',
        searchTerm: '',
        statusList: []
      })
    );
  }, []);

  const data = useSelector((state: any) => state.getNotificationListReducer);

  const selectedNotifications = data?.data?.length
    ? data?.data.filter((notification: INotifications) => !notification.read).slice(0, 3) ?? []
    : [];

  const handleToggle = async () => {
    setOpen((prevOpen) => !prevOpen);
    if (!open) {
      if (selectedNotifications.length > 0)
        await markedMultipleReadNotificationsService({
          notificationsIds: selectedNotifications.map((notification: any) => notification.id)
        });
    }
  };
  useEffect(() => {
    if (!data.loading) {
      setRead(data?.notReadCount ?? 0);
    }
  }, [data]);

  const handleMarkAllRead = async () => {
    try {
      dispatch(loadingAction(true));
      await markedAllReadNotificationsService();
      dispatch(
        getNotificationList({
          page: 1,
          limit: 10,
          searchTerm: '',
          statusList: []
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75, height: 44, width: 44 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, height: 44, width: 44 }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={read} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{ overflow: 'hidden' }} in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notifications"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {read > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => {
                              handleMarkAllRead();
                            }}
                          >
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {read > 0 &&
                      selectedNotifications.map((notification: INotifications) => {
                        const splitDate = formatDate(notification.created_at, `${defaultDateFormat} ${defaultDateTimeFormat}`).split(' ');
                        return (
                          <ListItemButton selected>
                            <ListItemText
                              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '300px' }}
                              primary={
                                <Typography component="span" variant="subtitle1" title={notification.description}>
                                  {notification.description}
                                </Typography>
                              }
                              secondary={splitDate[0]}
                            />
                            <ListItemSecondaryAction>
                              <Typography variant="caption" noWrap>
                                {splitDate[1]} {splitDate[2]}
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        );
                      })}
                    {read === 0 && (
                      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
                        <Typography variant="h6">No new notifications!</Typography>
                      </Stack>
                    )}
                    <Divider />
                    <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="h6"
                            color="primary"
                            onClick={() => {
                              handleToggle();
                              navigate('/notifications');
                            }}
                          >
                            View All
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;

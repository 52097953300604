/* eslint-disable @typescript-eslint/no-unused-expressions */
import endpoints from 'constants/endpoints';
import axiosServices from 'utils/axios';
import { errorMessageSnackbar, successMessageSnackbar } from 'utils/snackbar-message';

export const getBookingListService = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.getBookingList, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const getBookingListDetailService = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.getBookingListDetail, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const deleteBookingService = async (bookingId: number): Promise<any> => {
  try {
    const response = (await axiosServices.delete(endpoints.deleteBooking + bookingId)).data;
    response.error ? errorMessageSnackbar(response.message) : successMessageSnackbar('Booking deleted successfully.');
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const createAlertService = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.createAlert, data)).data;
    response.error ? errorMessageSnackbar(response.message) : successMessageSnackbar('Alert created successfully.');
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const deleteDocumentService = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.delete(endpoints.deletedDocument + data)).data;
    response.error ? errorMessageSnackbar(response.message) : successMessageSnackbar('Document deleted successfully.');
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const getVesselLocationService = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(`${endpoints.getVesselLocation}${data}`)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const getActivityListService = async (bookingId: number): Promise<any> => {
  try {
    const response = (await axiosServices.post(`${endpoints.postBooking}/${bookingId}/activity`)).data.data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Something went wrong');
  }
};

export const schedulePickup = async (bookingId: number, date: string): Promise<any> => {
  try {
    const response = (await axiosServices.put(`${endpoints.schedulePickup}/${bookingId}`, { rdw_empty_pick_up: date })).data;
    response.error ? errorMessageSnackbar(response.message) : successMessageSnackbar('Pickup date scheduled successfully');
    return response;
  } catch (err: any) {
    errorMessageSnackbar('Failed to schedule pickup date');
  }
};

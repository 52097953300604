import { Box } from '@mui/material';
import { ThresholdDateProgressCard } from 'components/ThresholdDateProgressCard/ThresholdDateProgressCard';
import { KeyboardEnterIcon } from 'components/icons/KeyboardEnterIcon';
import { add } from 'date-fns';
import React from 'react';
import { selectPerDiemStartDate } from 'sections/booking-list-detail/selectors';

export interface PerDiemStatusProps extends React.ComponentProps<typeof Box> {
  bookingDetail: any;
}

const CARD_TITLE = {
  before: 'Per Diem Starts in',
  after: 'Per Diem Started'
};

const DATE_LABEL = {
  before: 'Start Date',
  after: 'Started on'
};

const DEFAULT_PER_DIEM_DAYS = 7;

export const PerDiemStatus: React.FC<PerDiemStatusProps> = ({ bookingDetail, ...boxProps }) => {
  const perDiemStartDate = selectPerDiemStartDate(bookingDetail);
  if (!perDiemStartDate) {
    return null;
  }

  const progressEndDate = perDiemStartDate;
  const progressStartDate = add(progressEndDate, { days: -DEFAULT_PER_DIEM_DAYS });

  return (
    <ThresholdDateProgressCard
      cardTitle={CARD_TITLE}
      dateLabel={DATE_LABEL}
      startDate={progressStartDate}
      endDate={progressEndDate}
      icon={
        <Box component="span" sx={{ transform: 'scaleX(-1)' }}>
          <KeyboardEnterIcon />
        </Box>
      }
      {...boxProps}
    />
  );
};

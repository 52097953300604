import React from 'react';
import { Map, LngLat } from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// @ts-expect-error
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-expect-error
import polyline from '@mapbox/polyline';

export const ROUTE_LAYER = 'route';

export const useShowRoute = (map: React.MutableRefObject<Map | null>) => {
  const showRoute = React.useCallback(
    (polylineString: string, fromLatLng: LngLat, toLatLng: LngLat) => {
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.background = '#FFFFFF';
      el.style.border = '2px solid #40A9FF';
      el.style.borderRadius = '50%';
      el.style.width = '16px';
      el.style.height = '16px';
      const fromMarker = new mapboxgl.Marker(el).setLngLat(fromLatLng);
      const toMarker = new mapboxgl.Marker(el.cloneNode()).setLngLat(toLatLng);
      fromMarker.addTo(map.current);
      toMarker.addTo(map.current);

      map.current?.addSource(ROUTE_LAYER, {
        type: 'geojson',
        data: polyline.toGeoJSON(polylineString)
      });
      map.current?.addLayer({
        id: ROUTE_LAYER,
        type: 'line',
        source: ROUTE_LAYER,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#40A9FF',
          'line-width': 3,
          'line-opacity': 1
        }
      });
    },
    [map]
  );

  return showRoute;
};

import { Box, Typography } from '@mui/material';
import { CheckCircleIcon } from 'components/icons/CheckCircleIcon';
import { CloseCircleIcon } from 'components/icons/CloseCircleIcon';
import { WatchLaterIcon } from 'components/icons/WatchLaterIcon';
import React from 'react';

export type ChipVariant = 'success' | 'warning' | 'error';

export interface StatusChipProps extends React.ComponentProps<typeof Box> {
  label: string;
  variant: ChipVariant;
}

interface StatusColors {
  border: string;
  background: string;
  text: string;
}

const VARIANT_TO_ICON_MAP: Record<ChipVariant, JSX.Element> = {
  success: <CheckCircleIcon />,
  warning: <WatchLaterIcon />,
  error: <CloseCircleIcon />
};

const VARIANT_TO_COLORS_MAP: Record<ChipVariant, StatusColors> = {
  success: {
    border: '#B7EB8F',
    background: '#F6FFED',
    text: '#52C41A'
  },
  warning: {
    border: '#FFD591',
    background: '#FFF7E6',
    text: '#FA8C16'
  },
  error: {
    border: '#FFA39E',
    background: '#FFF1F0',
    text: '#F5222D'
  }
};

export const StatusChip: React.FC<StatusChipProps> = ({ label, variant, ...boxProps }) => {
  if (!variant) {
    return null;
  }
  return (
    <Box
      color={VARIANT_TO_COLORS_MAP[variant].text}
      borderColor={VARIANT_TO_COLORS_MAP[variant].border}
      sx={{ backgroundColor: VARIANT_TO_COLORS_MAP[variant].background }}
      display="inline-flex"
      gap="8px"
      alignItems="center"
      padding="6px"
      border="1px solid"
      borderRadius="4px"
      {...boxProps}
    >
      {VARIANT_TO_ICON_MAP[variant]}
      <Typography fontSize={12} fontWeight={400} textTransform="capitalize" noWrap>
        {label}
      </Typography>
    </Box>
  );
};

const baseUrl = process.env.REACT_APP_API_BASE_URL;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseUrl,
  getAddressBook: `${baseUrl}/v1/addressbook/dictionary`,
  deleteAddress: `${baseUrl}/v1/addressbook/delete/`,
  updateAddress: `${baseUrl}/v1/addressbook/update/`,
  postAddress: `${baseUrl}/v1/addressbook/create`,
  login: `${baseUrl}/v1/auth/login`,
  getEmployees: `${baseUrl}/v1/user/company/employees`,
  resendInvite: `${baseUrl}/v1/auth/invite-user/resend`,
  updateEmployee: `${baseUrl}/v1/user/company/employees/`,
  updateEmployeeRole: `${baseUrl}/v1/user/company/employees/roles`,
  postEmployee: `${baseUrl}/v1/auth/invite-user`,
  deleteEmployee: `${baseUrl}/v1/user/company/employees/`,
  getRoles: `${baseUrl}/v1/auth/roles`,
  createAlert: `${baseUrl}/v1/booking/createAlert`,
  getBookingListDetail: `${baseUrl}/v1/booking/bookingDetails`,
  getRefreshToken: `${baseUrl}/v1/auth/refresh-token`,
  getBookingList: `${baseUrl}/v1/booking/bookingList`,
  userNotification: `${baseUrl}/v1/user/notifications/channels`,
  resetPassword: `${baseUrl}/v1/auth/reset-password`,
  myProfile: `${baseUrl}/v1/auth/my-profile`,
  register: `${baseUrl}/v1/auth/register`,
  deletedDocument: `${baseUrl}/v1/booking/deleteDocument/`,
  getPort: `${baseUrl}/v1/port/dictionary`,
  getRamp: `${baseUrl}/v1/ramp/dictionary`,
  getRampPort: `${baseUrl}/v1/ramp/dictionary/port/`,
  getTerminal: `${baseUrl}/v1/terminal/dictionary`,
  getTerminalPort: `${baseUrl}/v1/terminal/dictionary/port/`,
  getCarrier: `${baseUrl}/v1/carrier/dictionary`,
  postGetRates: `${baseUrl}/v1/get-rates`,
  postBooking: `${baseUrl}/v1/booking/booking`,
  getInvoiceBalance: `${baseUrl}/v1/finance/invoice/balance`,
  getInvoiceList: `${baseUrl}/v1/finance/invoice/list`,
  updateBillingInfo: `${baseUrl}/v1/finance/invoice/update-billing-info`,
  getInvoiceDetail: `${baseUrl}/v1/finance/invoice/detail`,
  payInvoice: `${baseUrl}/v1/finance/invoice/pay`,
  payInvoiceWithCredit: `${baseUrl}/v1/finance/invoice/pay-with-credit`,
  payMultipleInvoice: `${baseUrl}/v1/finance/invoice/pay-multiple`,
  downloadInvoice: `${baseUrl}/v1/finance/invoice`,
  getBillingInfo: `${baseUrl}/v1/user/billing-info`,
  getUserWallet: `${baseUrl}/v1/user/wallet`,
  getMyRoles: `${baseUrl}/v1/auth/my-profile/roles`,
  getNotificationsList: `${baseUrl}/v1/notifications-history/dictionary`,
  markedAllReadNotifications: `${baseUrl}/v1/notifications-history/mark-all-read`,
  markedMultipleNotifications: `${baseUrl}/v1/notifications-history/mark-read-multiple`,
  presetFiltersNotifications: `${baseUrl}/v1/notifications-history/preset-filters`,
  getTerminalByPortId: `${baseUrl}/v1/terminal/dictionary/port/`,
  getZipCodes: `${baseUrl}/v1/address-components/zip`,
  getCountries: `${baseUrl}/v1/address-components/country`,
  getStates: `${baseUrl}/v1/address-components/state/country/`,
  getCitys: `${baseUrl}/v1/address-components/city/state/`,
  getZipCodesByCity: `${baseUrl}/v1/address-components/zip/city/`,
  addDocument: `${baseUrl}/v1/booking/addDocument`,
  deleteBooking: `${baseUrl}/v1/booking/cancel/`,
  getQuoteList: `${baseUrl}/v1/get-rates`,
  getVesselLocation: `${baseUrl}/v1/booking/booking/vessel/`,
  schedulePickup: `${baseUrl}/v1/booking/schedulePickup`
};

import { useEffect } from 'react';
import { useSelector } from 'store';

const useScript = (url: string, widgetCode: string) => {
  const { profile } = useSelector((state) => state.profileReducer);
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>"; 
    $zoho.salesiq.ready = function() {
        var profile = JSON.parse(localStorage.getItem('profileData'));
        $zoho.salesiq.floatbutton.position('bottomleft');
        if(profile?.uniqueId) {
            $zoho.salesiq.visitor.name(profile?.name + ' ' + profile?.surname + ' (' + profile?.uniqueId+ ')');
            $zoho.salesiq.visitor.email(profile?.email);
            $zoho.salesiq.visitor.contactnumber(profile?.phone);
        }
    }`;

    const ziqbtn = document.body.querySelector('.zsiq_floatmain') as HTMLDivElement;
    const ziqchat = document.body.querySelector('.siqembed') as HTMLDivElement;
    if (profile?.uniqueId) {
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
      if (ziqbtn) ziqbtn.style.display = 'block';
      if (ziqchat) ziqchat.style.display = 'block';
    } else {
      if (ziqbtn) ziqbtn.style.display = 'none';
      if (ziqchat) ziqchat.style.display = 'none';
    }
  }, [profile?.uniqueId]);
};

export default function SalesIQ() {
  return <div>{useScript('https://salesiq.zoho.eu/widget', 'siqbb5436e7ab16e0b1454d881d1f48f2aee296050d03bb263e4d8fdaf976ee50f2')}</div>;
}

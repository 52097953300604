// project import
import account from './account';
import bookings from './bookings';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [bookings, account]
};

export default menuItems;

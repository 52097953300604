import React from 'react';
import { RoadwayStatusStepper } from 'sections/booking-list-detail/RoadwayStatusStepper/RoadwayStatusStepper';

import { ViewInvoiceButton } from 'sections/booking-list-detail/ViewInvoiceButton';
import { selectInvoiceId } from 'sections/booking-list-detail/selectors';

export interface RoadwayStatusCompletedProps {
  bookingDetailData: any;
}

export const RoadwayStatusCompleted: React.FC<RoadwayStatusCompletedProps> = ({ bookingDetailData }) => {
  return (
    <>
      <RoadwayStatusStepper
        bookingDetail={bookingDetailData}
        position="absolute"
        right={0}
        top="50%"
        sx={{ transform: 'translateY(-50%)' }}
      />

      <ViewInvoiceButton
        position="absolute"
        bottom={0}
        left="50%"
        sx={{ transform: 'translateX(-50%)' }}
        invoiceId={selectInvoiceId(bookingDetailData)}
      />
    </>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteBookingService, getBookingListService } from 'services/bookingList.service';

export interface IToLocation {
  address: string;
  rampName: string;
  yardName: string;
  rampAddress: string;
  yardAddress: string;
  locationType: string;
  terminalName: string;
  terminalAddress: string;
}
export interface IFromLocation {
  address: string;
  rampName: string;
  yardName: string;
  rampAddress: string;
  yardAddress: string;
  locationType: string;
  terminalName: string;
  terminalAddress: string;
}

export interface ICarrier {
  iconUrl: string;
  website: string;
  companyName: string;
  containerPrefix: string;
}

export interface IData {
  id: number;
  bookingNo: string;
  containerNo: string;
  dopag: string;
  bookingStatus: string;
  bookingLifecycleStatus: string;
  roadwayStatus: string;
  transportationType: string;
  storageType: string;
  containerSize: string;
  isReefer: false;
  isOverweight: false;
  isHazardous: true;
  transportationFee: number;
  fullSurchargeFee: number;
  nyctTools: number;
  statenTerminalAdd: number;
  total: number;
  carrier: ICarrier;
  fromLocation: IFromLocation;
  toLocation: IToLocation;
  lastUpdated: string;
}

export interface BoosterListState {
  data: IData[];
  totalCount: number;
  payloadList: any;
  error: boolean;
  message: any;
  loading: boolean;
}

const initialState: BoosterListState = {
  data: [],
  totalCount: 0,
  payloadList: {
    page: 1,
    limit: 5,
    searchTerm: '',
    sortOrder: 'DESC',
    sortBy: 'id'
  },
  error: false,
  message: '',
  loading: true
};

export const getAllBookingList = createAsyncThunk('/bookingList', async (data: any, thunkAPI) => {
  try {
    const response = await getBookingListService(data);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const payloadAction = createAction('updatePayloadList', (data: any) => {
  return {
    payload: data
  };
});

export const deleteBooking = createAsyncThunk('/deleteBooking', async (bookingId: number, thunkAPI) => {
  try {
    const response = await deleteBookingService(bookingId);
    if (response.error) {
      return thunkAPI.rejectWithValue(response.message);
    }
    return bookingId;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAllBookingListReducer = createSlice({
  name: 'getAllBookingList',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllBookingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBookingList.fulfilled, (state, action) => {
        state.data = action.payload.data.bookingList ?? [];
        state.totalCount = action.payload.data.totalCount;
        state.error = action.payload.error;
        state.loading = false;
      })
      .addCase(getAllBookingList.rejected, (state, action) => {
        state.error = true;
        state.message = action.payload;
        state.loading = false;
      })
      .addCase(payloadAction, (state, action) => {
        state.payloadList = action.payload;
      })
      .addCase(deleteBooking.fulfilled, (state, action) => {
        state.data = state.data.filter((item) => item.id !== action.payload);
      });
  }
});

export default getAllBookingListReducer.reducer;

import { Box } from '@mui/system';
import React from 'react';
import { FromToAddressCard } from 'sections/booking-list-detail/FromToAddressCard';
import { LiveTrackingUrlButton } from 'sections/booking-list-detail/LiveTrackingUrlButton';
import { PerDiemStatus } from 'sections/booking-list-detail/PerDiemStatus';
import { RoadwayStatusStepper } from 'sections/booking-list-detail/RoadwayStatusStepper/RoadwayStatusStepper';
import { selectLiveTrackingUrl } from 'sections/booking-list-detail/selectors';

export interface RoadwayStatusDispatchedProps {
  bookingDetailData: any;
}

export const RoadwayStatusDispatched: React.FC<RoadwayStatusDispatchedProps> = ({ bookingDetailData }) => {
  return (
    <>
      <Box position="absolute" left={0} top={0} bottom={0} display="flex" flexDirection="column" gap="10px">
        <FromToAddressCard hideFrom from={bookingDetailData.fromLocation.terminalName} to={bookingDetailData.toLocation.address} />
        <PerDiemStatus bookingDetail={bookingDetailData} width="fit-content" my="auto" />
      </Box>

      <RoadwayStatusStepper
        bookingDetail={bookingDetailData}
        position="absolute"
        right={0}
        top="50%"
        sx={{ transform: 'translateY(-50%)' }}
      />

      <LiveTrackingUrlButton url={selectLiveTrackingUrl(bookingDetailData)} position="absolute" top={0} right={0} />
    </>
  );
};

import { Box, ButtonBase, Typography } from '@mui/material';
import { EventUpcomingIcon } from 'components/icons/EventUpcomingIcon';
import React from 'react';

export interface SchedulePickupButtonProps {
  onClick: () => void;
}

export const SchedulePickupButton: React.FC<SchedulePickupButtonProps> = ({ onClick }) => {
  return (
    <ButtonBase sx={{ width: '100%' }} onClick={onClick}>
      <Box
        sx={{
          width: '100%',
          padding: '9px 16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          color: '#FFFFFF',
          backgroundColor: '#1890FF',
          border: '1px solid #1890FF',
          boxShadow: '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
          borderRadius: ' 4px'
        }}
      >
        <EventUpcomingIcon height="14px" />
        <Typography fontSize={14} fontWeight={400}>
          Schedule Pickup
        </Typography>
      </Box>
    </ButtonBase>
  );
};

import {Box, Typography} from '@mui/material';
import React from 'react';

export interface BookingMainInfoProps extends React.ComponentProps<typeof Box> {
    bookingDetail: any;
}

export const BookingMainInfo: React.FC<BookingMainInfoProps> = ({bookingDetail, ...boxProps}) => {
    const handlingUnit = React.useMemo(() => {
        const acc = [];
        bookingDetail?.isReefer && acc.push('Reefer');
        bookingDetail?.isHazardous && acc.push('Hazardous');
        bookingDetail?.isOverweight && acc.push('Overweight');

        return acc.length > 0 ? acc.join(', ') : undefined;
    }, [bookingDetail]);

    const infoItems = React.useMemo<{ label: string; value: string }[]>(
        () =>
            [
                {label: 'Booking No', value: bookingDetail?.bookingNo},
                {
                    label: 'Transportation Type',
                    value: bookingDetail?.transportationType === 'live_load' ? 'Live Load' : 'Drop & Pick'
                },
                {label: 'Storage Type', value: bookingDetail?.storageType},
                {label: 'Container No', value: bookingDetail?.containerNo},
                {label: 'Container', value: bookingDetail?.containerSize},
                {
                    label: 'Handling Unit',
                    value: handlingUnit
                }
            ].map(({label, value}) => ({label, value: value || '-'})),
        [bookingDetail, handlingUnit]
    );

    return (
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 9px 28px 8px #0000000D, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F'
            }}
            borderRadius="4px"
            {...boxProps}
        >
            <Box borderBottom="1px solid #F0F0F0" display="flex" alignItems="center" padding="16px 24px">
                <Typography fontSize={16} fontWeight={500} color="#262626" mr="auto">
                    Information
                </Typography>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="20px" alignItems="center" padding="16px 24px">
                {infoItems.map(({label, value}) => (
                    <Box key={label} paddingX="24px" display="flex" justifyContent="space-between">
                        <Typography fontSize={14} fontWeight={400} color="#8C8C8C">
                            {label}
                        </Typography>
                        <Typography fontSize={14} fontWeight={500} color="#262626">
                            {value}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { handleRefreshTokenService, loginService } from 'services/auth.service';

export interface user {
  accessToken?: string;
  expiresIn: number;
  idToken?: string;
  scope?: any;
  tokenType: string;
}

export interface LoginState {
  user: user | undefined | any;
  status: 'loading' | 'failed' | 'success' | any;
  message: any;
  isLoggedIn: boolean;
  isInitialized: boolean;
}

const initialState: LoginState = {
  // eslint-disable-next-line
  user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')!) : {},
  status: localStorage.getItem('status') ? localStorage.getItem('status') : 'idle',
  message: '',
  isLoggedIn: false,
  isInitialized: false
};

export const login = createAsyncThunk('auth/login', async (data: any, thunkAPI) => {
  try {
    const response = await loginService(data);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getRefreshToken = createAsyncThunk('auth/refreshToken', async (data: any, thunkAPI) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')!);
  const props = {
    refreshToken: userInfo && userInfo.refreshToken
  };
  try {
    const response = await handleRefreshTokenService(props);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const logoutSite = createAction('auth/logout');

const loginReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(login.fulfilled, (state, action) => {
      state.status = 'success';
      state.user = action.payload.data;
      localStorage.setItem('userInfo', JSON.stringify(action.payload.data));
      state.isLoggedIn = true;
      state.isInitialized = true;
    })
    .addCase(login.rejected, (state, action) => {
      state.status = 'failed';
      state.message = action.payload;
    })
    .addCase(getRefreshToken.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getRefreshToken.fulfilled, (state, action) => {
      state.status = 'success';
      state.user = { ...state.user, ...action.payload.data };
      state.message = '';
      if (state.user) {
        localStorage.setItem('userInfo', JSON.stringify(state.user));
      }
    })
    .addCase(getRefreshToken.rejected, (state, action) => {
      state.status = 'failed';
      state.message = action.payload;
      localStorage.removeItem('userInfo');
      state.message = 'idle';
    })
    .addCase(logoutSite, (state) => {
      state.user = {};
      state.isLoggedIn = false;
      state.isInitialized = false;
      localStorage.removeItem('userInfo');
    });
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const userData = (state: any) => state.login.user;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default loginReducer;

import { LngLatBounds } from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// @ts-expect-error
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-expect-error
import polyline from '@mapbox/polyline';

export const getRouteBounds = (polylineString: string) => {
  const bounds = new mapboxgl.LngLatBounds() as LngLatBounds;
  polyline.toGeoJSON(polylineString).coordinates.forEach((coordinate: any) => {
    bounds.extend(coordinate);
  });

  return bounds;
};

import MainCard from 'components/MainCard';
import { Grid, Typography } from '@mui/material';

interface Props {
  bookingDetailData: any;
}

function Instructions({ bookingDetailData }: Props) {
  // const getHandlingUnit = (data: any) => {
  //   if (!data) return;
  //   const acc = [];
  //   data?.isReefer && acc.push('Reefer');
  //   data?.isHazardous && acc.push('Hazardous');
  //   data?.isOverweight && acc.push('Overweight');

  //   return acc.length > 0 ? acc.join(', ') : '-';
  // };

  const getName = (location: any) => {
    switch (location.locationType) {
      case 'train':
        return (
          <span>
            {location.rampName} <span style={{ color: '#8c8c8c' }}> (Train Terminal)</span>
          </span>
        );
      case 'ocean':
        return (
          <span>
            {location.terminalName} <span style={{ color: '#8c8c8c' }}> (Ocean Terminal)</span>
          </span>
        );
      case 'yard':
        return (
          <span>
            {location.yardName} <span style={{ color: '#8c8c8c' }}> (Ocean Terminal)</span>
          </span>
        );
      case 'address':
        return (
          <span>
            {location.address} <span style={{ color: '#8c8c8c' }}> (Address)</span>
          </span>
        );
    }
  };

  const getAddress = (location: any) => {
    switch (location.locationType) {
      case 'train':
        return (
          <span style={{ color: '#8c8c8c' }}>
            <span style={{ fontWeight: 'bold' }}>Ramp Address: </span> {location.rampAddress}
          </span>
        );
      case 'ocean':
        return (
          <span style={{ color: '#8c8c8c' }}>
            <span style={{ fontWeight: 'bold' }}>Terminal Address: </span>
            {location.terminalAddress}
          </span>
        );
      case 'yard':
        return (
          <span style={{ color: '#8c8c8c' }}>
            <span style={{ fontWeight: 'bold' }}>Yard Address: </span>
            {location.yardAddress}
          </span>
        );
      case 'address':
        return (
          <span style={{ color: '#8c8c8c' }}>
            <span style={{ fontWeight: 'bold' }}>Address: </span> {location.address}
          </span>
        );
    }
  };

  return (
    <MainCard title={`Booking Information - ${bookingDetailData?.carrier.companyName}`}>
      <Grid container spacing={3} sx={{ pb: 4, pr: 4 }}>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography color={'#8C8C8C'}>{bookingDetailData?.carrier.companyName} Booking No</Typography>
          <strong>{bookingDetailData?.bookingNo}</strong>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography color={'#8C8C8C'}>Bill of Lading</Typography>
          <strong>{bookingDetailData?.billOfLading}</strong>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography color={'#8C8C8C'}>Container No</Typography>
          <strong>{bookingDetailData?.containerNo}</strong>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography color={'#8C8C8C'}>Reference</Typography>
          <strong>{bookingDetailData?.referenceNo}</strong>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography color={'#8C8C8C'}>Transportation Type</Typography>
          <strong>{bookingDetailData?.transportationType === 'live_load' ? 'Live Load' : 'Drop and Pick'}</strong>
        </Grid>
        {/* <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Handling Unit</Typography>
          <strong>{getHandlingUnit(bookingDetailData)}</strong>
        </Grid> */}
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography color={'#8C8C8C'}>Content Description</Typography>
          <strong>{bookingDetailData?.dopag}</strong>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="flex-start" alignItems="flex-start">
          <Typography color={'#8C8C8C'}>From</Typography>
          <span>
            {getName(bookingDetailData?.fromLocation)} <br />
            {getAddress(bookingDetailData?.fromLocation)}
            {bookingDetailData.fromLocation.locationType === 'ocean' && (
              <span style={{ color: '#8c8c8c' }}>
                <br />
                <span style={{ fontWeight: 'bold' }}>Carrier: </span> {bookingDetailData.carrier.companyName}
              </span>
            )}
          </span>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="flex-start" alignItems="flex-start">
          <Typography color={'#8C8C8C'}>To</Typography>
          <span>
            {getName(bookingDetailData?.toLocation)} <br />
            {getAddress(bookingDetailData?.toLocation)}
          </span>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default Instructions;

import MainCard from 'components/MainCard';
import { Grid, Typography, Stack, Divider } from '@mui/material';
// import { useTheme } from "@mui/material/styles";

interface Props {
  bookingDetailData: any;
}
export default function RateBreakdown({ bookingDetailData }: Props) {
  return (
    <Grid item xs={12} md={12} xl={12} sx={{ height: '100%' }}>
      <MainCard title="Rate Breakdown" sx={{ height: '100%', position: 'relative' }}>
        <Stack spacing={1}>
          {bookingDetailData?.transportationFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Transportation</Typography>
              <strong>${bookingDetailData?.transportationFee.toFixed(2)}</strong>
            </Stack>
          )}
          {bookingDetailData?.fuelSurchargeFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Fuel Surcharge</Typography>
              <strong>${bookingDetailData?.fuelSurchargeFee.toFixed(2)}</strong>
            </Stack>
          )}
          {bookingDetailData?.dropPickFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Drop & Pick</Typography>
              <strong>${bookingDetailData?.dropPickFee.toFixed(2)}</strong>
            </Stack>
          )}
          {bookingDetailData?.chassisFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Chasis</Typography>
              <strong>${bookingDetailData?.chassisFee.toFixed(2)}</strong>
            </Stack>
          )}
          {bookingDetailData?.tollFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Toll</Typography>
              <strong>${bookingDetailData?.tollFee.toFixed(2)}</strong>
            </Stack>
          )}

          {bookingDetailData?.residentialFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Residential Area</Typography>
              <strong>${bookingDetailData?.residentialFee.toFixed(2)}</strong>
            </Stack>
          )}

          {bookingDetailData?.prePullFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Pre Pull</Typography>
              <strong>${bookingDetailData?.prePullFee.toFixed(2)}</strong>
            </Stack>
          )}

          {bookingDetailData?.reeferFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Reefer</Typography>
              <strong>${bookingDetailData?.reeferFee.toFixed(2)}</strong>
            </Stack>
          )}

          {bookingDetailData?.hazardousFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Hazardous</Typography>
              <strong>${bookingDetailData?.hazardousFee.toFixed(2)}</strong>
            </Stack>
          )}

          {bookingDetailData?.overweightFee > 0 && (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Overweight</Typography>
              <strong>${bookingDetailData?.overweightFee.toFixed(2)}</strong>
            </Stack>
          )}
          <Divider />
          <div>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography color="InfoText">Total</Typography>
              <strong>${bookingDetailData?.total.toFixed(2)}</strong>
            </Stack>
          </div>
        </Stack>
      </MainCard>
    </Grid>
  );
}

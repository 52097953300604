/* eslint-disable @typescript-eslint/no-explicit-any */
import endpoints from 'constants/endpoints'
import axiosServices from 'utils/axios'
import { errorMessageSnackbar, successMessageSnackbar } from 'utils/snackbar-message'

export async function loginService(data: any) {
    const response = await axiosServices.post(endpoints.login, data)
    return response.data
}
export async function registerService(data: any) {
    try {
        const response: any = (await axiosServices.post(endpoints.register, data)).data
        response.error ? errorMessageSnackbar(response.message) : successMessageSnackbar('Profile  created successfully.')
        return response
    } catch (err: any) {
        err.error && errorMessageSnackbar(err?.message || "Something went wrong")
    }
}

export async function handleRefreshTokenService(data: any) {
    const response = await axiosServices.post(endpoints.getRefreshToken, data)
    return response.data
}

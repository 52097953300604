/* eslint-disable @typescript-eslint/no-unused-expressions */
import endpoints from 'constants/endpoints';
import axiosServices from 'utils/axios';
import { errorMessageSnackbar, successMessageSnackbar } from 'utils/snackbar-message';

export const getPortsServ = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getPort)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getRampsServ = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getRamp)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getTerminalsServ = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getTerminal)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};
export const getTerminalsByIdServ = async (id: string): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getTerminalByPortId + id)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getRampPortServ = async (id: string): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getRampPort + id)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getTerminalPortsServ = async (id: string): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getTerminalPort + id)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getCarriersServ = async (): Promise<any> => {
  try {
    const response = (await axiosServices.get(endpoints.getCarrier)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const getRatesServ = async (data: any): Promise<any> => {
  try {
    const response = (await axiosServices.post(endpoints.postGetRates, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const updateRatesLocation = async (param: string, data: any): Promise<any> => {
  try {
    const response = (await axiosServices.put(endpoints.postGetRates + param, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar("Can't update location");
  }
};

export const updateRatesInstruction = async (param: string, data: any): Promise<any> => {
  try {
    const response = (await axiosServices.put(endpoints.postGetRates + param, data)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    successMessageSnackbar('Successfully updated.');
    return response;
  } catch (err: any) {
    errorMessageSnackbar("Can't update instruction");
  }
};

export const singleGetRatesServ = async (id: string): Promise<any> => {
  try {
    const response = (await axiosServices.get(`${endpoints.getQuoteList}/${id}`)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

export const saveQuoteServ = async (id: number): Promise<any> => {
  try {
    const response = (await axiosServices.post(`${endpoints.postGetRates}/${id}/save`)).data;
    response.error ? errorMessageSnackbar(response.message) : null;
    successMessageSnackbar('Successfully saved.');
    return response;
  } catch (err: any) {
    errorMessageSnackbar(err.message);
  }
};

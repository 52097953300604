import { CardContent, Grid, Stack, Typography } from '@mui/material';

import Avatar from 'components/@extended/Avatar';

import { WarningFilled, InfoCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { format } from 'date-fns';

interface Props {
  activities: any[];
}

const ActivityComp = ({ activities }: Props) => {
  return activities?.length > 0 ? (
    <CardContent>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{
          position: 'relative',
          '&>*': {
            position: 'relative',
            zIndex: '5'
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            top: 10,
            left: 5,
            width: 2,
            height: '100%',
            background: '#ebebeb',
            zIndex: '1'
          }
        }}
      >
        {activities.map((activity: any) => (
          <Grid item xs={12} key={activity?.action}>
            <Grid key={activity?.action} container spacing={2}>
              <Grid item xs={1}>
                <Avatar size="sm" sx={{ width: 21, height: 21, top: 15, right: 20, backgroundColor: '#fff', border: '1px solid #F0F0F0' }}>
                  {activity?.status === 'warning' ? (
                    <WarningFilled style={{ color: '#FFB600' }} />
                  ) : activity?.status === 'success' ? (
                    <CheckCircleFilled style={{ color: '#00B533' }} />
                  ) : (
                    <InfoCircleFilled style={{ color: '#007AFF' }} />
                  )}
                </Avatar>
              </Grid>
              <Grid item zeroMinWidth xs={11}>
                <Grid container spacing={0}>
                  <Grid item container xs={9}>
                    <Grid item xs={12}>
                      <Typography align="left" variant="body2">
                        {activity?.action}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography align="left" variant="caption" color="secondary">
                        {activity.status === 'ezport_event' && JSON.parse(activity?.data)?.description}
                      </Typography>
                      <Typography align="left" variant="caption" color="secondary">
                        {activity.status === 'transport_event' && 'Location Locode: ' + JSON.parse(activity?.data)?.location_locode}
                      </Typography>
                      <Typography align="left" variant="caption" color="secondary">
                        {activity.status === 'transport_event' && 'Voyage Number: ' + (JSON.parse(activity?.data)?.voyage_number ?? '-')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack>
                      <Typography align="right" variant="body2">
                        {format(new Date(activity?.actionAt), 'MM/dd')}
                      </Typography>
                      <Typography fontSize={10} align="right" variant="caption" color="secondary">
                        {format(new Date(activity?.actionAt), 'hh:mm a')}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </CardContent>
  ) : (
    <Typography>There are no activities for this booking.</Typography>
  );
};

export default ActivityComp;

import MainCard from 'components/MainCard';
import { Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

interface Props {
  bookingDetailData: any;
}

function VoyageInformations({ bookingDetailData }: Props) {
  const eta = bookingDetailData?.tracking?.eta ? format(new Date(), 'dd MMM yyyy') : '';

  return (
    <MainCard title={`Voyage Information`} style={{ height: '100%' }}>
      <Grid container spacing={4} sx={{ pb: 4, pr: 4 }}>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Booking Status</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {bookingDetailData?.bookingLifecycleStatus}
          </Typography>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Roadway Status</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {bookingDetailData?.roadwayStatus}
          </Typography>
        </Grid>

        {/* ETA */}
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>ETA</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {eta}
          </Typography>
        </Grid>

        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Container No</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {bookingDetailData?.containerNo}
          </Typography>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Container Type</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {bookingDetailData?.containerSize}
          </Typography>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Voyage</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {bookingDetailData?.tracking?.inboundVoyageNumber + ' - ' + bookingDetailData?.tracking?.outboundVoyageNumber}
          </Typography>
        </Grid>
        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Vessel</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {bookingDetailData?.tracking?.vesselName}
          </Typography>
        </Grid>
        {/* Release */}

        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Terminal Status</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            Not Loaded
          </Typography>
        </Grid>

        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Freight Release</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            Released
          </Typography>
        </Grid>

        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Custom Release</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            Not Released
          </Typography>
        </Grid>

        <Grid item xs={6} container direction={'column'} justifyContent="space-between" alignItems="flex-start">
          <Typography>Last Update</Typography>
          <Typography variant="subtitle1" textTransform="capitalize">
            {bookingDetailData?.lastUpdated}
          </Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default VoyageInformations;

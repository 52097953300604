export const WavySample = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="8" fill="none">
    <path
      stroke="#1890FF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 4c4.444 3.333 8.889 3.333 13.333 0C18.778.667 23.223.667 27.667 4 32.11 7.333 36.556 7.333 41 4"
    />
  </svg>
);

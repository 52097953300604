import { Box } from '@mui/system';
import { RateBreakDown } from 'pages/booking-list/RateBreakDown';
import React from 'react';
import { BookingDetailBackground } from 'sections/booking-list-detail/BookingDetailBackground';
import { BookingDetailDashboard } from 'sections/booking-list-detail/BookingDetailWithBackground/BookingDetailDashboard';
import { BookingMainInfo } from 'sections/booking-list-expanding-detail/BookingMainInfo';
import { VerticalBookingStatusStepper } from 'sections/booking-list-expanding-detail/VerticalBookingStatusStepper';

export interface BookingDetailWithBackgroundProps extends React.ComponentProps<typeof Box> {
  bookingDetailData: any;
  reloadBookingDetail: () => Promise<void>;
  variant?: 'full' | 'preview';
}

export const BookingDetailWithBackground: React.FC<BookingDetailWithBackgroundProps> = ({
  bookingDetailData,
  reloadBookingDetail,
  variant = 'full',
  ...boxProps
}) => {
  const isPreview = variant === 'preview';

  return (
    <Box position="relative" height="100%" {...boxProps} minHeight="600px">
      <BookingDetailBackground bookingDetailData={bookingDetailData} position="absolute" width="100%" height="100%" />

      <Box
        position="relative"
        display="grid"
        height="100%"
        gridTemplateColumns="auto 1fr auto"
        gridTemplateRows={isPreview ? 'auto auto' : '1fr'}
        columnGap="10px"
        rowGap="22px"
        p="20px"
      >
        <BookingMainInfo bookingDetail={bookingDetailData} display={isPreview ? 'block' : 'none'} gridArea="1/1/1/4" />

        <VerticalBookingStatusStepper
          bookingDetail={bookingDetailData}
          display={isPreview ? 'flex' : 'none'}
          gridArea="2/1/2/2"
          height="fit-content"
        />

        <RateBreakDown
          bookingDetail={bookingDetailData}
          minWidth="250px"
          sx={{ backgroundColor: '#FFFFFF' }}
          display={isPreview ? 'block' : 'none'}
          gridArea="2/3/2/4"
        />

        <Box position="relative" width="100%" height="100%" gridArea={isPreview ? '2/2/2/3' : '1/1/1/4'}>
          <BookingDetailDashboard bookingDetailData={bookingDetailData} reloadBookingDetail={reloadBookingDetail} />
        </Box>
      </Box>
    </Box>
  );
};

import { Box } from '@mui/material';
import React from 'react';
import { selectBookingId, selectEmptyPickUpDate } from 'sections/booking-list-detail/selectors';
import { EditPickupDateDialog } from 'sections/booking-list-detail/SchedulePickupButton/EditPickupDateDialog';
import { PickupScheduledCard } from 'sections/booking-list-detail/SchedulePickupButton/PickupScheduledCard';
import { SchedulePickupButton } from 'sections/booking-list-detail/SchedulePickupButton/SchedulePickupButton';
import { schedulePickup } from 'services/bookingList.service';

export interface PickupDateSchedulerProps extends React.ComponentProps<typeof Box> {
  reloadBookingDetail: () => Promise<void>;
  bookingDetail: any;
}
export const PickupDateScheduler: React.FC<PickupDateSchedulerProps> = ({ bookingDetail, reloadBookingDetail, ...boxProps }) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);

  const openDialog = React.useCallback(() => {
    setIsEditDialogOpen(true);
  }, []);

  const closeDialog = React.useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const handleSavePickupDate = React.useCallback(
    async (date: Date) => {
      const bookingId = selectBookingId(bookingDetail);
      if (!bookingId) {
        return;
      }
      await schedulePickup(bookingId, date.toISOString());
      setIsEditDialogOpen(false);
      reloadBookingDetail();
    },
    [bookingDetail, reloadBookingDetail]
  );

  const emptyPickUpDate = selectEmptyPickUpDate(bookingDetail);

  return (
    <Box width="100%" {...boxProps}>
      {emptyPickUpDate ? (
        <PickupScheduledCard value={new Date(emptyPickUpDate)} onEditClick={openDialog} />
      ) : (
        <SchedulePickupButton onClick={openDialog} />
      )}

      <EditPickupDateDialog
        value={emptyPickUpDate ? new Date(emptyPickUpDate) : undefined}
        open={isEditDialogOpen}
        onCancel={closeDialog}
        onSave={handleSavePickupDate}
      />
    </Box>
  );
};

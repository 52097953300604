import axios from 'axios';
import { dispatch } from 'store';
import { getRefreshToken } from 'store/reducers/auth/login';

const axiosServices = axios.create();

// ==============================|| AXIOS SERVICES ||============================== //


axiosServices.interceptors.request.use(request => {

  // eslint-disable-next-line
  const userInfo = JSON.parse(localStorage.getItem('userInfo')!)
  const accessToken = userInfo && userInfo.accessToken

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
},
  (error) => {
    return Promise.reject(error);
  }
)

axiosServices.interceptors.response.use(
  (response) => response,
  async error => {
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.data.error) {
        if (error.response.data.message === 'expired_token' || error.response.data.message === 'invalid_token') {
          originalRequest._retry = true;
          await dispatch(getRefreshToken(''))
          const userInfo = JSON.parse(localStorage.getItem('userInfo')!)
          const accessToken = userInfo && userInfo.accessToken
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`
          return axios(originalRequest);
        }
      }
    }


    return Promise.reject((error.response && error.response.data) || 'Wrong Services')
  }
);

export default axiosServices;

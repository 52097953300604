/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getNotificationsListService } from 'services/notification.service';

export interface INotifications {
  id: number;
  description: string;
  read: boolean;
  type: string;
  user_id: number;
  created_at: string;
  isChecked: boolean;
}

export interface IData {
  notifications: INotifications[];
}

export interface NotificationListState {
  data: IData;
  count: number;
  notReadCount: number;
  payloadList: any;
  error: boolean;
  message: any;
  loading: boolean;
}

const initialState: NotificationListState = {
  data: { notifications: [] },
  count: 0,
  notReadCount: 0,
  payloadList: {
    page: 1,
    limit: 10,
    searchTerm: '',
    sortOrder: 'ASC',
    sortBy: 'created_at'
  },
  error: false,
  message: '',
  loading: true
};

export const getNotificationList = createAsyncThunk('/notificationList', async (data: any, thunkAPI) => {
  try {
    const response = await getNotificationsListService(data);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const payloadAction = createAction('updatePayloadList', (data: any) => {
  return {
    payload: data
  };
});

export const loadingAction = createAction('updateLoadingNotificationAction', (data: boolean) => {
  return {
    payload: data
  };
});

export const getNotificationListReducer = createSlice({
  name: 'getNotificationList',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getNotificationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        state.data = action.payload?.data?.notifications;
        state.count = action.payload?.data?.count;
        state.notReadCount = action.payload?.data?.isNotReadCount;
        state.error = action.payload?.error;
        state.loading = false;
      })
      .addCase(getNotificationList.rejected, (state, action) => {
        state.error = true;
        state.message = action.payload;
        state.loading = false;
      })
      .addCase(payloadAction, (state, action) => {
        state.payloadList = action.payload;
      })
      .addCase(loadingAction, (state, action) => {
        state.loading = action.payload;
      });
  }
});

export default getNotificationListReducer.reducer;

import React from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { ChipVariant, StatusChip } from 'sections/booking-list-detail/TerminalContainerStatus/StatusChip';
import { format } from 'date-fns';
import { SyncIcon } from 'components/icons/SyncIcon';

export type TerminalStatusVariant = 'available' | 'pending' | 'hold';

export interface TerminalStatusCardProps extends React.ComponentProps<typeof Box> {
  title: string;
  status: string;
  variant: TerminalStatusVariant;
  date?: Date;
  dateLabel?: string;
  onRefresh?: () => void;
}

const VARIANT_MAP: Record<TerminalStatusVariant, ChipVariant> = {
  available: 'success',
  pending: 'warning',
  hold: 'error'
};

export const TerminalStatusCard: React.FC<TerminalStatusCardProps> = ({
  title,
  status,
  variant,
  date,
  dateLabel,
  onRefresh,
  ...boxProps
}) => {
  const bottomLine = React.useMemo(() => {
    const dateString = date ? format(date, "d MMM, yy'' H.mm aaa") : undefined;

    if (dateString && dateLabel) {
      return `${dateLabel} ${dateString}`;
    }

    if (dateString) {
      return dateString;
    }

    if (dateLabel) {
      return dateLabel;
    }

    return undefined;
  }, [date, dateLabel]);

  return (
    <Box padding="10px 16px" position="relative" {...boxProps}>
      <Typography fontSize={14} fontWeight={500} color="#262626">
        {title}
      </Typography>

      <StatusChip mt="8px" label={status} variant={VARIANT_MAP[variant]} />

      {bottomLine ? (
        <Typography fontSize={11} fontWeight={500} color="#26262680" mt="10px">
          {bottomLine}
        </Typography>
      ) : null}

      {onRefresh ? (
        <ButtonBase
          onClick={onRefresh}
          sx={{
            position: 'absolute',
            top: 10,
            right: 16,
            width: 24,
            height: 24,
            borderRadius: 12,
            border: '1px solid #D9D9D9',
            boxShadow: '0px 2px 0px 0px #00000004'
          }}
        >
          <SyncIcon />
        </ButtonBase>
      ) : null}
    </Box>
  );
};

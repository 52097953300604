// Loading.tsx

import Loader from 'components/Loader';
import React, { useEffect } from 'react';
import { useSelector } from 'store';

const Loading: React.FC = () => {
  const data = useSelector((state: any) => state.loadingReducer);
  useEffect(() => {}, [data.isLoading]);

  if (!data.isLoading) {
    return null;
  }

  return <Loader />;
};

export default Loading;

import React from 'react';
import { RoadwayStatusCompleted } from 'sections/booking-list-detail/BookingInRoadwayStatus/RoadwayStatusCompleted';
import { RoadwayStatusDispatched } from 'sections/booking-list-detail/BookingInRoadwayStatus/RoadwayStatusDispatched';
import { RoadwayStatusEmptyPickedUp } from 'sections/booking-list-detail/BookingInRoadwayStatus/RoadwayStatusEmptyPickedUp';
import { RoadwayStatusPickedUp } from 'sections/booking-list-detail/BookingInRoadwayStatus/RoadwayStatusPickedUp';
import { RoadwayStatusPickedUpOngoing } from 'sections/booking-list-detail/BookingInRoadwayStatus/RoadwayStatusPickedUpOngoing';
import { RoadwayStatusUndefined } from 'sections/booking-list-detail/BookingInRoadwayStatus/RoadwayStatusUndefined';
import { RoadwayStatus, selectRoadwayStatus } from 'sections/booking-list-detail/selectors';

export interface BookingInRoadwayStatusProps {
  bookingDetailData: any;
  reloadBookingDetail: () => Promise<void>;
}

const RoadwayStatusComponents: Record<RoadwayStatus, React.FC<{ bookingDetailData: any; reloadBookingDetail: () => Promise<void> }>> = {
  picked_up: RoadwayStatusPickedUp,
  dispatched: RoadwayStatusDispatched,
  picked_up_ongoing: RoadwayStatusPickedUpOngoing,
  empty_picked_up: RoadwayStatusEmptyPickedUp,
  completed: RoadwayStatusCompleted
};

export const BookingInRoadwayStatus: React.FC<BookingInRoadwayStatusProps> = ({ bookingDetailData, reloadBookingDetail }) => {
  const roadwayStatus = selectRoadwayStatus(bookingDetailData);

  if (!roadwayStatus) {
    return <RoadwayStatusUndefined bookingDetailData={bookingDetailData} />;
  }

  const Component = RoadwayStatusComponents[roadwayStatus];

  return <Component bookingDetailData={bookingDetailData} reloadBookingDetail={reloadBookingDetail} />;
};

import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

export const successMessageSnackbar = (message: string) => {
  dispatch(
    openSnackbar({
      open: true,
      message: message,
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: true
    })
  );
};

export const errorMessageSnackbar = (message: string) => {
  dispatch(
    openSnackbar({
      open: true,
      message: message ?? 'Something went wrong',
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: true
    })
  );
};

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { GoldOutlined, UnorderedListOutlined, BookOutlined, PlusCircleOutlined, OrderedListOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  GoldOutlined,
  UnorderedListOutlined,
  BookOutlined,
  PlusCircleOutlined,
  OrderedListOutlined
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const bookings: NavItemType = {
  id: 'group-booking-nav',
  title: <FormattedMessage id="Bookings" />,
  icon: icons.GoldOutlined,
  type: 'group',
  children: [
    {
      id: 'new-booking',
      title: <FormattedMessage id="New Booking" />,
      type: 'item',
      url: '/new-booking',
      icon: icons.PlusCircleOutlined,
      breadcrumbs: true
    },
    {
      id: 'booking-list',
      title: <FormattedMessage id="Booking List" />,
      type: 'item',
      url: '/booking-list',
      icon: icons.UnorderedListOutlined,
      breadcrumbs: true
    },
    {
      id: 'quote-list',
      title: <FormattedMessage id="Quote List" />,
      type: 'item',
      url: '/quote-list',
      icon: icons.OrderedListOutlined,
      breadcrumbs: true
    },
    {
      id: 'adress-book',
      title: <FormattedMessage id="Adress Book" />,
      type: 'item',
      icon: icons.BookOutlined,
      url: '/adress-book'
    }
  ]
};

export default bookings;

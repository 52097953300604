// material-ui

import React from 'react';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */
    <svg width="27" height="35" viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.88537 12.6163L10.9375 7.65625L19.4162 21.4341L24.4454 13.3875C28.0877 7.55959 23.898 0 17.0252 0H1.97343C1.11435 0 0.59062 0.944955 1.04594 1.67344L7.88537 12.6163Z"
        fill="#1890FF"
      />
      <path
        d="M10.9378 8.75L26.2981 33.3266C26.7534 34.0551 26.2297 35 25.3706 35L10.3187 35C3.44612 35 -0.743757 27.4404 2.89869 21.6125L10.9378 8.75Z"
        fill="#262626"
      />
    </svg>
  );
};

export default LogoIcon;

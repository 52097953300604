import { Box, Typography } from '@mui/material';
import { CalendarIcon } from 'components/icons/CalendarIcon';
import { ClockCircleIcon } from 'components/icons/ClockCircleIcon';
import { EventUpcomingIcon } from 'components/icons/EventUpcomingIcon';
import { format } from 'date-fns';
import React from 'react';
import { PickupDateField } from 'sections/booking-list-detail/PickupDateCard/PickupDateField';

export interface PickupDateCardProps extends React.ComponentProps<typeof Box> {
  date: string;
}

export const PickupDateCard: React.FC<PickupDateCardProps> = ({ date: dateString, ...boxProps }) => {
  const date = new Date(dateString);
  const pickUpDate = format(date, 'd MMM, yyyy');
  const pickUpTime = format(date, 'h:mmaaa');

  return (
    <Box
      p="16px 20px"
      sx={{
        boxShadow: '0px 9px 28px 8px #0000000D, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F',
        backgroundColor: '#FFFFFF'
      }}
      borderRadius="4px"
      display="grid"
      gridTemplateColumns="1fr"
      gap="12px"
      {...boxProps}
    >
      <Box position="relative" display="flex" alignItems="center" justifyContent="center" gap="8px" color="#1890FF">
        <EventUpcomingIcon height="20px" />

        <Typography fontSize={14} lineHeight="1.6" fontWeight={700} color="#262626" align="center" pr="20px">
          Pickup Date
        </Typography>
      </Box>

      <PickupDateField icon={<CalendarIcon />} label={pickUpDate} />
      <PickupDateField icon={<ClockCircleIcon />} label={pickUpTime} />
    </Box>
  );
};

export default function TruckIcon({ color, ...props }: any = { color: '#1890FF' }) {
  return (
    <svg width="57" height="21" viewBox="0 0 57 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M51.4115 16.0283C50.1576 16.0283 49.0918 17.0941 49.0918 18.348C49.0918 19.6018 50.1576 20.6677 51.4115 20.6677C52.6653 20.6677 53.7311 19.6019 53.7311 18.348C53.7316 17.0311 52.6653 16.0283 51.4115 16.0283Z"
        fill={color}
      />
      <path d="M56.427 16.9678H54.1699C54.2954 16.9678 54.358 17.8455 54.358 18.2846V18.8488H56.427L56.427 16.9678Z" fill={color} />
      <path d="M0 0.666992H37.618V15.0872H0V0.666992Z" fill={color} />
      <path
        d="M33.8568 16.0283C32.603 16.0283 31.5371 17.0941 31.5371 18.348C31.5371 19.6018 32.6029 20.6677 33.8568 20.6677C35.1106 20.6677 36.1764 19.6019 36.1764 18.348C36.1769 17.0311 35.1736 16.0283 33.8568 16.0283Z"
        fill={color}
      />
      <path
        d="M26.7708 16.0283C25.517 16.0283 24.4512 17.0941 24.4512 18.348C24.4512 19.6018 25.517 20.6677 26.7708 20.6677C28.0247 20.6677 29.0905 19.6019 29.0905 18.348C29.0905 17.0311 28.0873 16.0283 26.7708 16.0283Z"
        fill={color}
      />
      <path
        d="M24.3265 15.9014H23.4488C22.5085 15.9014 21.7559 16.6536 21.7559 17.5943V18.5346H23.3233V18.284C23.3858 17.4062 23.7619 16.528 24.3265 15.9014Z"
        fill={color}
      />
      <path
        d="M31.4114 15.7139H29.1543C29.7814 16.341 30.1575 17.0932 30.1575 18.0335V18.2216H30.4081V18.0335C30.4711 17.0933 30.8472 16.3406 31.4114 15.7139Z"
        fill={color}
      />
      <path d="M43.2601 15.7139H36.3008C36.9279 16.341 37.304 17.0932 37.304 18.0335V18.2216H43.2601L43.2601 15.7139Z" fill={color} />
      <path
        d="M12.1009 16.0283C10.8471 16.0283 9.78125 17.0941 9.78125 18.348C9.78125 19.6018 10.847 20.6677 12.1009 20.6677C13.3548 20.6677 14.4206 19.6019 14.4206 18.348C14.421 17.0311 13.3552 16.0283 12.1009 16.0283Z"
        fill={color}
      />
      <path
        d="M5.01498 16.0283C3.76116 16.0283 2.69531 17.0941 2.69531 18.348C2.69531 19.6018 3.7611 20.6677 5.01498 20.6677C6.26886 20.6677 7.33464 19.6019 7.33464 18.348C7.33464 17.0311 6.26886 16.0283 5.01498 16.0283Z"
        fill={color}
      />
      <path d="M2.57068 15.9014H0V18.5346H1.56742V18.284C1.56742 17.4063 1.94354 16.528 2.57068 15.9014Z" fill={color} />
      <path
        d="M14.3574 15.9014H15.2352C16.1754 15.9014 16.9281 16.6536 16.9281 17.5943V18.5346H15.3607V18.284C15.3607 17.4062 14.9846 16.528 14.3574 15.9014Z"
        fill={color}
      />
      <path
        d="M9.65552 15.7139H7.39844C8.02558 16.341 8.40168 17.0932 8.40168 18.0335V18.2216H8.65228V18.0335C8.65272 17.0933 9.02882 16.3406 9.65552 15.7139Z"
        fill={color}
      />
      <path d="M38.8711 3.67647V15.0873H43.2597V5.68276H46.4572C43.2597 3.17512 39.4983 3.67669 38.8711 3.67669V3.67647Z" fill={color} />
      <path
        d="M55.6754 14.8991C55.1737 13.4572 55.4873 10.761 50.785 10.761C49.5937 8.88001 49.4057 8.19034 49.4057 8.19034H49.8448C49.8448 8.19034 49.7822 6.93652 48.6534 6.93652H43.9512V18.8489H48.2143C48.2143 18.2217 48.1518 18.5353 48.1518 18.3473C48.1518 16.5293 49.5937 14.8988 51.4121 14.8988C52.3524 14.8988 53.1676 15.0869 53.7947 15.714H56.3024C56.1768 15.0873 55.8003 15.2754 55.6752 14.8993L55.6754 14.8991ZM45.7693 10.4479V8.19079H47.7758L49.2177 10.8241C49.2177 10.8241 46.396 11.012 45.7693 10.4479Z"
        fill={color}
      />
    </svg>
  );
}

import React from 'react';
import { selectBookingLifecycleStatus, selectRoadwayStatus } from 'sections/booking-list-detail/selectors';

import ShipLoadingImage from 'assets/images/voyage/ship_loading.png';
import ShipSailingImage from 'assets/images/voyage/ship-sailing.png';
import ShipUnloadingImage from 'assets/images/voyage/ship-unloading.png';
import TruckAndALargeGreenCheckMarkImage from 'assets/images/voyage/truck-and-a-large-green-check-mark-banner.png';
import TruckOffloadingAtAStorageFacilityImage from 'assets/images/voyage/truck-offloading-at-a-storage-facility-banner.png';
import MapBackground from 'sections/booking-list-detail/MapBackground/MapBackground';
import { Box } from '@mui/system';

export interface BookingDetailBackgroundProps extends React.ComponentProps<typeof Box> {
  bookingDetailData: any;
}

const IMAGE_STYLE: React.CSSProperties = { width: '100%', height: '100%', objectFit: 'cover' };

export const BookingDetailBackground: React.FC<BookingDetailBackgroundProps> = ({ bookingDetailData, ...boxProps }) => {
  const bookingLifecycleStatus = selectBookingLifecycleStatus(bookingDetailData);
  const roadwayStatus = selectRoadwayStatus(bookingDetailData);

  return (
    <Box {...boxProps}>
      {bookingLifecycleStatus === 'booked' ? <img src={ShipLoadingImage} alt="Loading ship" style={IMAGE_STYLE} /> : null}

      {bookingLifecycleStatus === 'ocean_voyage' ? <img src={ShipSailingImage} alt="Sailing ship" style={IMAGE_STYLE} /> : null}

      {bookingLifecycleStatus === 'terminal' ? <img src={ShipUnloadingImage} alt="Unloading ship" style={IMAGE_STYLE} /> : null}

      {bookingLifecycleStatus === 'roadway' && !roadwayStatus ? (
        <MapBackground variant="pin" hidePin bookingDetailData={bookingDetailData} />
      ) : null}

      {bookingLifecycleStatus === 'roadway' && roadwayStatus === 'picked_up' ? (
        <MapBackground variant="pin" bookingDetailData={bookingDetailData} />
      ) : null}

      {bookingLifecycleStatus === 'roadway' && roadwayStatus === 'dispatched' ? (
        <MapBackground variant="path" bookingDetailData={bookingDetailData} />
      ) : null}

      {bookingLifecycleStatus === 'roadway' && (roadwayStatus === 'picked_up_ongoing' || roadwayStatus === 'empty_picked_up') ? (
        <img src={TruckOffloadingAtAStorageFacilityImage} alt="Truck offloading at a storage facility" style={IMAGE_STYLE} />
      ) : null}

      {bookingLifecycleStatus === 'completed' || (bookingLifecycleStatus === 'roadway' && roadwayStatus === 'completed') ? (
        <img src={TruckAndALargeGreenCheckMarkImage} alt="Truck and a large green check mark" style={IMAGE_STYLE} />
      ) : null}
    </Box>
  );
};

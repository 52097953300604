export default function WarehouseIcon({ color, ...props }: any = { color: '#1890FF' }) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.9996 8.21497C19.9996 7.65652 19.7246 7.13997 19.258 6.8315L10.9165 0.948324C10.3582 0.573215 9.6249 0.573215 9.06665 0.948324L0.741761 6.8315C0.283364 7.13996 0 7.65636 0 8.21497V19.8231C0 20.2815 0.374958 20.6564 0.83334 20.6564H2.50002V11.4981C2.50002 11.0397 2.87498 10.6648 3.33336 10.6648H16.6666C17.1333 10.6648 17.5 11.0398 17.5 11.4981V20.6564H19.1667C19.6333 20.6564 20 20.2815 20 19.8231L19.9996 8.21497ZM9.99985 8.16479C8.61653 8.16479 7.49983 7.04809 7.49983 5.66477C7.49983 4.28977 8.61653 3.16475 9.99985 3.16475C11.383 3.16475 12.4999 4.28962 12.4999 5.66477C12.4999 7.04809 11.383 8.16479 9.99985 8.16479Z"
        fill={color}
      />
      <path
        d="M4.99032 13.9899H14.9901C15.4507 13.9899 15.8234 13.6172 15.8234 13.1566C15.8234 12.6959 15.4507 12.3232 14.9901 12.3232H4.99032C4.52968 12.3232 4.15698 12.6959 4.15698 13.1566C4.15698 13.6172 4.52967 13.9899 4.99032 13.9899Z"
        fill={color}
      />
      <path
        d="M4.99032 17.3229H14.9901C15.4507 17.3229 15.8234 16.9502 15.8234 16.4896C15.8234 16.0289 15.4507 15.6562 14.9901 15.6562H4.99032C4.52968 15.6562 4.15698 16.0289 4.15698 16.4896C4.15698 16.9504 4.52967 17.3229 4.99032 17.3229Z"
        fill={color}
      />
      <path
        d="M4.99032 20.6564H14.9901C15.4507 20.6564 15.8234 20.2837 15.8234 19.8231C15.8234 19.3624 15.4507 18.9897 14.9901 18.9897H4.99032C4.52968 18.9897 4.15698 19.3624 4.15698 19.8231C4.15698 20.2837 4.52967 20.6564 4.99032 20.6564Z"
        fill={color}
      />
    </svg>
  );
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@mui/material';
import { PopupTransition } from 'components/@extended/Transitions';
import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';

export interface EditPickupDateDialogProps {
  open: boolean;
  value?: Date;
  onCancel: () => void;
  onSave: (value: Date) => Promise<void>;
}

export const EditPickupDateDialog: React.FC<EditPickupDateDialogProps> = ({ open, onCancel, onSave, value: initialValue = new Date() }) => {
  const [value, setValue] = React.useState(initialValue);
  const [loading, setLoading] = React.useState(false);

  const handleDateTimeChange = React.useCallback((newValue: string | null, keyboardInputValue?: string | undefined) => {
    if (!newValue) {
      return;
    }
    setValue(new Date(newValue));
  }, []);

  const handleSave = React.useCallback(() => {
    setLoading(true);

    onSave(value).finally(() => {
      setLoading(false);
    });
  }, [onSave, value]);

  return (
    <Dialog
      maxWidth="sm"
      TransitionComponent={PopupTransition}
      onClose={onCancel}
      open={open}
      sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Edit Pickup Date</DialogTitle>
      <Divider />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DialogContent sx={{ p: 2.5, display: 'flex', gap: '8px', width: '350px' }}>
          <DesktopDatePicker
            inputFormat="dd MMM, yy''"
            value={value}
            onChange={handleDateTimeChange}
            renderInput={(params) => <TextField fullWidth {...params} placeholder="Date" />}
            disablePast={true}
            disableMaskedInput
          />
          <DesktopTimePicker
            inputFormat="KK:mm aaa"
            value={value}
            onChange={handleDateTimeChange}
            renderInput={(params) => <TextField fullWidth {...params} placeholder="Time" />}
            disableMaskedInput
          />
        </DialogContent>
      </LocalizationProvider>

      <DialogActions sx={{ px: 2.5, pb: 2.5 }}>
        <Button color="error" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained" onClick={handleSave} loading={loading}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { Box } from '@mui/material';
import React from 'react';

export interface StepDotProps {
  icon?: JSX.Element;
}

export const StepDot: React.FC<StepDotProps> = ({ icon }) => {
  return (
    <Box position="relative">
      <Box width="10px" height="10px" border="2px solid #1890FF" borderRadius="50%" />
      <Box
        position="absolute"
        color="#1890FF"
        bottom={7}
        sx={{
          transform: 'translateX(-50%)',
          left: '50%'
        }}
      >
        {icon}
      </Box>
    </Box>
  );
};

import { Box, Typography } from '@mui/material';
import React from 'react';
import { selectRateBrakeDownItems, selectRateBrakeDownTotal } from 'sections/booking-list-detail/selectors';

export interface RateBreakDownProps extends React.ComponentProps<typeof Box> {
  bookingDetail: any;
}

export const RateBreakDown: React.FC<RateBreakDownProps> = ({ bookingDetail, sx, ...boxProps }) => {
  const rateBrakeDownItems = selectRateBrakeDownItems(bookingDetail);

  const items = React.useMemo(
    () =>
      rateBrakeDownItems
        .filter((item) => Boolean(item.value))
        .map(({ title, value }) => ({
          title,
          value: `$${value?.toFixed(2)}`
        })),
    [rateBrakeDownItems]
  );

  const total = selectRateBrakeDownTotal(bookingDetail);

  return (
    <Box
      sx={{
        boxShadow: '0px 9px 28px 8px #0000000D, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F',
        ...sx
      }}
      borderRadius="4px"
      display="flex"
      flexDirection="column"
      {...boxProps}
    >
      <Typography fontSize={16} fontWeight={500} color="#262626" m="16px 24px">
        Rate Breakdown
      </Typography>

      <Box borderBottom="1px solid #F0F0F0" />

      <Box m="16px 24px" display="flex" flexDirection="column" gap="16px">
        {items.map(({ title, value }) => (
          <Box key={title} display="flex" justifyContent="space-between">
            <Typography fontSize={14} fontWeight={400} color="#8C8C8C" mr="16px">
              {title}
            </Typography>
            <Typography fontSize={14} fontWeight={500} color="#262626">
              {value}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box borderBottom="1px solid #F0F0F0" />

      <Box display="flex" justifyContent="space-between" m="16px 24px">
        <Typography fontSize={14} fontWeight={500} color="#262626" mr="16px">
          {total.title}
        </Typography>
        <Typography fontSize={14} fontWeight={500} color="#262626">
          {`$${total.value?.toFixed(2)}`}
        </Typography>
      </Box>
    </Box>
  );
};

// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import addressBook from './addressBook';
import loginReducer from './auth/login';
import employees from './user/employees';
import getAllBookingListReducer from './bookingList';
import getAllInvoiceListReducer from './invoiceList';
import getNotificationListReducer from './notificationList';
import loadingReducer from './loadingReducer';
import profileReducer from './user/profile';
import walletReducer from './user/wallet';
import getAllQuoteListReducer from './quoteList';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  addressBook,
  loginReducer,
  employees,
  getAllBookingListReducer,
  getAllInvoiceListReducer,
  getNotificationListReducer,
  loadingReducer,
  profileReducer,
  walletReducer,
  getAllQuoteListReducer
});

export type RootState = ReturnType<typeof reducers>;
export default reducers;

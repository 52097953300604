import { Box, Typography } from '@mui/material';
import React from 'react';
import { StepIcon } from 'sections/booking-list-detail/BookingStatusStepper/StepIcon';

export interface StepperItem {
  value: string;
  title?: string;
}

export interface VerticalStepperStepperProps extends React.ComponentProps<typeof Box> {
  steps: StepperItem[];
  value?: string;
}

export const VerticalStepper: React.FC<VerticalStepperStepperProps> = ({ steps, value: currentValue, sx, ...boxProps }) => {
  const activeIndex = React.useMemo(() => steps.findIndex(({ value }) => value === currentValue), [currentValue, steps]);

  return (
    <Box
      display="flex"
      gap="6px"
      flexDirection="column"
      borderRadius="4px"
      padding="20px"
      sx={{
        boxShadow: '0px 9px 28px 8px #0000000D, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F',
        backgroundColor: '#FFFFFF',
        ...sx
      }}
      {...boxProps}
    >
      {steps.map((step, index) => (
        <React.Fragment key={step.value}>
          <Box key={step.value} display="flex" gap="16px">
            <StepIcon current={index} active={activeIndex} total={steps.length} />
            <Typography fontSize={14} fontWeight={400} color={getLabelColor(index, activeIndex)}>
              {step.title || step.value}
            </Typography>
          </Box>
          {index !== steps.length - 1 ? (
            <Box height="12px" ml="11.5px" borderLeft="1px solid" color={index < activeIndex ? '#1890FF' : '#D9D9D9'} />
          ) : null}
        </React.Fragment>
      ))}
    </Box>
  );
};

function getLabelColor(index: number, activeIndex: number) {
  if (index === activeIndex) {
    return '#0959AF';
  }

  return index < activeIndex ? '#096DD9A1' : '#8C8C8C';
}

import React from 'react';
import { Box, Typography } from '@mui/material';
import { GaugeIndicator } from 'components/GaugeIndicator/GaugeIndicator';
import { compareAsc, format, differenceInCalendarDays } from 'date-fns';

export type ThresholdDateProgressColorVariant = 'before' | 'after';

export interface ThresholdDateProgressCardProps extends React.ComponentProps<typeof Box> {
  cardTitle: Record<ThresholdDateProgressColorVariant, string>;
  dateLabel: Record<ThresholdDateProgressColorVariant, string>;
  icon: JSX.Element;
  startDate: Date;
  endDate: Date;
}

const ASSET = {
  before: {
    primaryColor: '#1890FF',
    secondaryColor: '#E9E9E9',
    prepositionGridArea: '1/1/2/2'
  },
  after: {
    primaryColor: '#F5222D',
    secondaryColor: '#FFA39E',
    prepositionGridArea: '3/1/4/2'
  }
};

export const ThresholdDateProgressCard: React.FC<ThresholdDateProgressCardProps> = ({
  icon,
  cardTitle,
  dateLabel,
  startDate,
  endDate,
  sx,
  ...boxProps
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- we need fresh date with every render
  const now = new Date();
  const hasThresholdPassed = compareAsc(endDate, now) >= 0;
  const diffDays = differenceInCalendarDays(endDate, now);
  const value = Math.abs(diffDays);
  const variant = hasThresholdPassed ? 'before' : 'after';
  const preposition = hasThresholdPassed ? 'In' : 'Ago';
  const unit = value === 1 ? 'Day' : 'Days';

  const progress = React.useMemo(() => {
    const isLessThanStartDate = compareAsc(now, startDate) <= 0;
    const isLargerThanEndDate = compareAsc(now, endDate) >= 0;

    if (isLessThanStartDate) {
      return 0;
    }

    if (isLargerThanEndDate) {
      return 1;
    }

    const progressDays = differenceInCalendarDays(startDate, now);
    const totalDays = differenceInCalendarDays(startDate, endDate);

    return progressDays / totalDays;
  }, [endDate, now, startDate]);

  return (
    <Box
      padding="16px 10px"
      borderRadius="4px"
      display="grid"
      gap="12px"
      sx={{
        boxShadow: '0px 9px 28px 8px #0000000D, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001F',
        backgroundColor: '#FFFFFF',
        ...sx
      }}
      {...boxProps}
    >
      <Typography fontSize={14} lineHeight="1.6" fontWeight={700} color="#262626" align="center" pl="30px" mr="20px" position="relative">
        {cardTitle[variant]}
        <Box component="span" position="absolute" left={0} top={0} color={ASSET[variant].primaryColor}>
          {icon}
        </Box>
      </Typography>

      <GaugeIndicator
        progress={progress}
        size={70}
        totalColor={ASSET[variant].secondaryColor}
        progressColor={ASSET[variant].primaryColor}
        marginX="auto"
      >
        <Box display="grid" gridTemplateColumns="1fr">
          {diffDays ? (
            <>
              <Typography
                fontSize={12}
                lineHeight="1.08"
                fontWeight={400}
                color={ASSET[variant].primaryColor}
                align="center"
                gridArea={ASSET[variant].prepositionGridArea}
              >
                {preposition}
              </Typography>

              <Typography fontSize={16} lineHeight="1.08" fontWeight={700} color={ASSET[variant].primaryColor} align="center">
                {value}
              </Typography>

              <Typography fontSize={12} lineHeight="1.08" fontWeight={400} color={ASSET[variant].primaryColor} align="center">
                {unit}
              </Typography>
            </>
          ) : (
            <Typography fontSize={14} lineHeight="1.08" fontWeight={400} color={ASSET[variant].primaryColor} align="center" mt="4px">
              Today
            </Typography>
          )}
        </Box>
      </GaugeIndicator>

      <Box>
        <Typography fontSize={12} lineHeight="1.6" fontWeight={700} color="#192129B2" align="center">
          {dateLabel[variant]}
        </Typography>
        <Typography fontSize={12} lineHeight="1.6" fontWeight={400} color="#192129B2" align="center">
          {format(endDate, 'd MMM, yyyy')}
        </Typography>
      </Box>
    </Box>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getQuoteListService } from 'services/quoteList.service';

const initialState = {
  data: [],
  totalCount: 0,
  payloadList: {
    page: 1,
    limit: 5,
    searchTerm: '',
    sortOrder: 'DESC',
    sortBy: 'id'
  },
  error: false,
  message: '',
  loading: true
};

export const getAllQuoteList = createAsyncThunk('/quoteList', async (data: any, thunkAPI) => {
  try {
    const response = await getQuoteListService(data);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const payloadAction = createAction('updatePayloadList', (data: any) => {
  return {
    payload: data
  };
});

export const getAllQuoteListReducer = createSlice({
  name: 'getAllQuoteList',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllQuoteList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllQuoteList.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.totalCount = action.payload.data.length;
        state.error = action.payload.error;
        state.loading = false;
      })
      .addCase(getAllQuoteList.rejected, (state, action) => {
        state.error = true;
        state.message = action.payload as string;
        state.loading = false;
      })
      .addCase(payloadAction, (state, action) => {
        state.payloadList = action.payload;
      });
  }
});

export default getAllQuoteListReducer.reducer;

import React from 'react';
import { Skeleton, Stack, Fade } from '@mui/material';

interface SkeletonListProps {
  count: number;
}

const SkeletonList: React.FC<SkeletonListProps> = ({ count }) => {
  const skeletonArray = Array.from({ length: count }, (_, index) => (
    <Fade in timeout={{ enter: 0, exit: 100 }} key={`skeleton-${index}`}>
      <Skeleton
        animation="wave"
        sx={{
          width: '100%',
          height: '30px'
        }}
      />
    </Fade>
  ));

  return (
    <Stack gap={2} sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh', animation: 'fadein 2s infinite' }}>
      {skeletonArray}
    </Stack>
  );
};

export default SkeletonList;

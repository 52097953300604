// loadingSlice

import { createSlice, createAction } from '@reduxjs/toolkit';

interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false
};

export const setLoading = createAction('updateloadingAction', (data: boolean) => {
  return {
    payload: data
  };
});

export const loadingReducer = createSlice({
  name: 'loading',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(setLoading, (state, action) => {
      state.isLoading = action.payload;
    });
  }
});

export default loadingReducer.reducer;

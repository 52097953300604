import { Box, Typography } from '@mui/material';
import { differenceInCalendarDays, format, add } from 'date-fns';
import { GaugeIndicator } from 'components/GaugeIndicator/GaugeIndicator';

import EtaBG from 'assets/images/eta-bg.png';
import { InfoField } from 'sections/booking-list-detail/InfoField';
import React from 'react';
import { DEFAULT_OCEAN_VOYAGE_DURATION_DAYS } from 'constants/constants';

export interface VoyageEtaProps {
  etaDate?: string;
  startDate?: string;
  inboundVoyage: string;
  outboundVoyage: string;
  vessel: string;
  containerNo: string;
  containerType: string;
}

const DEFAULT_DATE_FORMAT = 'dd MMM, yyyy';

const VoyageEta: React.FC<VoyageEtaProps> = ({
  etaDate: etaDateString,
  startDate: startDateString,
  inboundVoyage,
  outboundVoyage,
  vessel,
  containerNo,
  containerType
}) => {
  const etaDate = etaDateString ? new Date(etaDateString) : add(new Date(), { days: 0 });
  const startDate = startDateString ? new Date(startDateString) : add(etaDate, { days: -DEFAULT_OCEAN_VOYAGE_DURATION_DAYS });

  const eta = format(new Date(etaDate), DEFAULT_DATE_FORMAT);
  const daysLeft = differenceInCalendarDays(etaDate, new Date());
  const daysTotal = differenceInCalendarDays(etaDate, startDate);
  const progress = daysLeft > daysTotal ? 0 : (daysTotal - daysLeft) / daysTotal;

  const voyage = React.useMemo(() => {
    if (inboundVoyage && outboundVoyage) {
      return `${inboundVoyage} - ${outboundVoyage}`;
    }

    if (inboundVoyage) {
      return inboundVoyage;
    }

    if (outboundVoyage) {
      return outboundVoyage;
    }

    return '-';
  }, [inboundVoyage, outboundVoyage]);

  return (
    <Box display="flex" borderRadius="0.3rem">
      <Box sx={{ borderRadius: '0.3rem 0 0 0.3rem', position: 'relative' }}>
        <img
          src={EtaBG}
          alt="eta"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '0.3rem 0 0 0.3rem'
          }}
        />

        <Box position="relative" display="flex" gap="2rem" padding="12px 24px 8px 24px" alignItems="center" width="220px">
          {etaDateString ? (
            <Box>
              <Typography fontSize={14} fontWeight={500} color="#fff">
                {daysLeft > 0 ? 'ETA' : 'ARRIVED'}
              </Typography>
              <Typography fontSize={14} fontWeight={500} color="#fff" noWrap>
                {eta}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography fontSize={14} fontWeight={500} color="#fff">
                ETA
              </Typography>
              <Typography fontSize={14} fontWeight={500} color="#fff">
                -
              </Typography>
            </Box>
          )}
          {etaDateString && daysLeft > 0 ? (
            <GaugeIndicator progress={progress} size={56} progressColor="#FFFFFF" totalColor="#91D5FF">
              <Typography fontSize={14} fontWeight={700} color="#FFFFFF" align="center" lineHeight="14px">
                {daysLeft}
              </Typography>
              <Typography fontSize={12} fontWeight={400} color="#FFFFFF" align="center" lineHeight="12px">
                {daysLeft === 1 ? 'Day' : 'Days'}
              </Typography>
            </GaugeIndicator>
          ) : null}
        </Box>
      </Box>

      <Box
        display="flex"
        gap={4}
        alignItems="center"
        justifyContent="space-between"
        p="10px 22px"
        sx={{ backgroundColor: '#fff', borderRadius: '0.3rem', width: '100%' }}
      >
        <InfoField label="Voyage" value={voyage} />
        <InfoField label="Vessel" value={vessel} />
        <InfoField label="Container No" value={containerNo} />
        <InfoField label="Container Type" value={containerType} />
      </Box>
    </Box>
  );
};

export default VoyageEta;

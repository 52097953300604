/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getInvoiceListServ } from 'services/finance.service';

export interface IInvoice {
  id: number;
  invoiceNumber: string;
  invoiceDate: string;
  bookingNo: string;
  dueDate: string;
  invoiceStatus: string;
  totalAmount: number;
  balanceDue: number;
  invoiceUrl: string;
}

export interface InvoiceListState {
  data: IInvoice[];
  totalCount: number;
  payloadList: any;
  error: boolean;
  message: any;
  loading: boolean;
}

const initialState: InvoiceListState = {
  data: [],
  totalCount: 0,
  payloadList: {
    page: 1,
    limit: 5,
    searchTerm: '',
    sortOrder: 'DESC',
    sortBy: 'id',
    statusList: []
  },
  error: false,
  message: '',
  loading: true
};

export const getInvoiceList = createAsyncThunk('/invoiceList', async (data: any, thunkAPI) => {
  try {
    const response = await getInvoiceListServ(data);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const payloadInvoiceAction = createAction('updatePayloadList', (data: any) => {
  return {
    payload: data
  };
});

export const getAllInvoiceListReducer = createSlice({
  name: 'getAllInvoiceList',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoiceList.fulfilled, (state, action) => {
        state.data = action.payload.data.invoiceList ?? [];
        state.totalCount = action.payload.data.totalCount;
        state.error = action.payload.error;
        state.loading = false;
      })
      .addCase(getInvoiceList.rejected, (state, action) => {
        state.error = true;
        state.message = action.payload;
        state.loading = false;
      })
      .addCase(payloadInvoiceAction, (state, action) => {
        state.payloadList = action.payload;
      });
  }
});

export default getAllInvoiceListReducer.reducer;
